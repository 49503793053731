import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';

import './Confirmation.scss';

import { Navigate, useParams } from 'react-router-dom';

import { CONVERSATION_SCHEDULED, PAGES } from '../../constants/analytics';
import { ERROR } from '../../constants/routes';
import useRateReviews from '../../hooks/useRateReviews';
import useSettingsQuery from '../../api/settings/use-settings-query.ts';
import Container from '../../kit_deprecated/Container/Container.tsx';
import analytics from '../../utils/analytics.util.ts';
import {
  CALENDLY_EVENT_SCHEDULED,
  CALENDLY_EVENT_TYPE_VIEWED,
  CALENDLY_TIME_SELECTED,
  CALENDLY_WIDGET_SCRIPT_URL,
  filterCalendlyEvents,
} from '../../utils/calendly.util.ts';
import { ContainerSize } from '../../kit_deprecated/Container/Container.type.ts';
import MaticTeam from './MaticTeam';

function trackConversationScheduled(gid: string) {
  analytics.trackV1(CONVERSATION_SCHEDULED, gid);
}

function trackPageView(gid: string): void {
  const props = { session_gid: gid };
  analytics.page(PAGES.CONFIRMATION, props);
}

const Confirmation: FC = () => {
  const gid = (useParams as any)().gid;
  const { data: rateReviewData, isLoading, isError } = useRateReviews(gid);
  const { data: settings } = useSettingsQuery();
  const [step, setStep] = useState(0);
  const { url } = settings?.calendly || {};
  const { first_name, last_name, phone, email } = rateReviewData?.person || {};

  const stepsClassNames = ['', '', ' c-confirmation__widget--last-step', ' c-confirmation__widget--confirm'];
  const widgetClassName = `c-confirmation__widget${stepsClassNames[step]}`;

  const onCalendlyEvent = useCallback(
    (eventName: string) => {
      switch (eventName) {
        case CALENDLY_EVENT_TYPE_VIEWED:
          setStep(1);
          break;
        case CALENDLY_TIME_SELECTED:
          setStep(2);
          break;
        case CALENDLY_EVENT_SCHEDULED:
          setStep(3);
          trackConversationScheduled(gid);
          window.scrollTo(0, 0);
          break;
      }
    },
    [gid, setStep],
  );

  const initCalendlyWidget = useCallback(() => {
    (window as any).Calendly.initInlineWidget({
      url,
      parentElement: document.getElementById('calendly-widget'),
      prefill: {
        name: first_name && last_name ? `${first_name} ${last_name}` : '',
        email: email || '',
        customAnswers: {
          a1: phone ? `1${phone}` : '1',
          a2: phone ? `1${phone}` : '1',
        },
      },
      utm: {
        utmMedium: 'serweb',
        utmTerm: gid,
      },
    });
  }, [first_name, last_name, phone, gid, email, url]);

  const addCalendlyScriptTag = useCallback(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = CALENDLY_WIDGET_SCRIPT_URL;
    script.onload = initCalendlyWidget;
    document.head.appendChild(script);
  }, [initCalendlyWidget]);

  useEffect(() => {
    if (!isLoading && settings?.calendly?.enabled) {
      window.addEventListener('message', filterCalendlyEvents(onCalendlyEvent));

      if ((window as any).Calendly) {
        initCalendlyWidget();
      }
      else {
        addCalendlyScriptTag();
      }
    }
  }, [addCalendlyScriptTag, initCalendlyWidget, isLoading, onCalendlyEvent, settings?.calendly?.enabled]);

  useEffect(() => {
    trackPageView(gid);
  }, [gid]);

  if (isError) {
    return <Navigate to={{ pathname: ERROR }} />;
  }

  return (
    <div className="c-confirmation">
      <Container size={ContainerSize.Small}>
        <div className="c-confirmation__header">
          <div className="c-confirmation__header--details">
            {step === 3
              ? (
                  <>
                    <h1 className="u-mb-1">You’re All Set!</h1>
                    <p className="o-paragraph--large u-mb-2">
                      A confirmation email with your appointment details has been sent.
                    </p>
                  </>
                )
              : (
                  <>
                    <h1 className="u-mb-1">Schedule a Call</h1>
                    <p className="o-paragraph--large u-mb-2">
                      A Matic agent will reach out to you at the scheduled time to discuss your insurance needs.
                    </p>
                  </>
                )}
          </div>
        </div>

        <div id="calendly-widget" data-testid="calendly-widget" className={widgetClassName} />

        <div className="c-confirmation__matic-team">
          <MaticTeam />
        </div>
      </Container>
    </div>
  );
};

export default Confirmation;
