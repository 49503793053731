import { z } from 'zod';

export const SessionSchema = z.object({
  gid: z.string(),
  finished_at: z.string().datetime({ offset: true }).nullable(),
});

export const SessionResponseSchema = z.object({
  session: SessionSchema.nullable(),
});
