import React from 'react';
import { demoFlowIndicatorCSS } from './PreviewModeIndicator.style';

const PreviewModeIndicator: React.FC = () => {
  return (
    <div css={demoFlowIndicatorCSS}>Preview Mode</div>
  );
};

export default PreviewModeIndicator;
