import { z } from 'zod';

export const PremiumHistoryItemSchema = z.object({
  policy_premium: z.string().nullable(),
  quote_premium: z.string().nullable(),
});

export const ReplacementCostHistoryItemSchema = z.object({
  replacement_cost: z.string().nullable(),
  replacement_cost_min: z.string().nullable(),
  replacement_cost_max: z.string().nullable(),
});

export const QuotesOverTimeHistoryItemSchema = z.number().nullable();

function HistoryDataSchema<T extends z.ZodTypeAny>(itemSchema: T) {
  return z.record(z.string(), itemSchema);
}

function HistoryResponseSchema<T extends z.ZodTypeAny>(itemSchema: T) {
  return z.object({
    history: HistoryDataSchema(itemSchema),
  });
}

export const PremiumHistoryResponseSchema = HistoryResponseSchema(PremiumHistoryItemSchema);
export const ReplacementCostHistoryResponseSchema = HistoryResponseSchema(ReplacementCostHistoryItemSchema);
export const QuotesOverTimeHistoryResponseSchema = HistoryResponseSchema(QuotesOverTimeHistoryItemSchema);
