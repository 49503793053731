import CheckmarkWithRowIcon from '../../assets/svg/sign-up/checkmark-with-row.svg?react';
import ChartsIcon from '../../assets/svg/sign-up/charts.svg?react';
import ChatBubblesIcon from '../../assets/svg/sign-up/chat-bubbles.svg?react';
import SpeedometerWithShieldIcon from '../../assets/svg/sign-up/speedometer-with-shield.svg?react';
import PersonWithChatBubbleIcon from '../../assets/svg/person-with-chat-bubble.svg?react';
import DisplayIcon from '../../assets/svg/sign-up/display.svg?react';
import MobileProtectionScoreIcon from '../../assets/svg/sign-up/mobile-protection-score.svg?react';
import PersonWithScreenIcon from '../../assets/svg/sign-up/person-with-screen.svg?react';
import type { HowItWorksProps } from './HowItWorksCard/HowItWorksCard.type';

export const TOOLS_TO_OPTIMIZE_DATA = [
  {
    icon: <ChartsIcon />,
    description: `With Matic’s RateReview® monitoring service, we proactively shop 40+ 
    A-rated carriers and alert you if we find a lower rate.`,
    title: 'RateReview® Monitoring',
  },
  {
    icon: <SpeedometerWithShieldIcon />,
    description: `Get an easy-to-understand view of how your home is protected. 
    See your personalized risk and protection scores along with simplified summaries 
    of your coverage to help you identify gaps.`,
    title: 'Protection Snapshot',
  },
  {
    icon: <ChatBubblesIcon />,
    description: `See up-to-date estimates of the cost to rebuild your home, which 
    can help you decide if you\'re over-insured, under-insured, or just right.`,
    title: 'Home Replacement Cost Calculator',
  },
  {
    icon: <CheckmarkWithRowIcon />,
    description: `Stay informed on which carriers are offering coverage in your area. 
    We’ll notify you when new options become available, so you have transparency into the insurance marketplace.`,
    title: 'Carrier Options',
  },
];

export const HOW_IT_WORKS_DATA: HowItWorksProps[] = [
  {
    description: 'Provide a few details about yourself to sign up. Then, verify your account via email.',
    icon: <PersonWithChatBubbleIcon width={364} />,
    title: 'Create your account',
  },
  {
    description: `Tell us about your property through an easy online form. This helps us 
    personalize your coverage insights and set up proactive rate monitoring.`,
    icon: <DisplayIcon />,
    iconOrientation: 'right',
    title: 'Build your profile',
  },
  {
    description: `View your personalized risk and protection scores. See how well your current 
    coverage aligns with your needs and how insurers might assess your home’s risk level.`,
    icon: <MobileProtectionScoreIcon />,
    title: 'Review your Protection Snapshot',
  },
  {
    description: `We proactively search a network of 40+ A-rated carriers for better rates and 
    notify you about changes that could impact your recommended coverage.`,
    icon: <PersonWithScreenIcon />,
    iconOrientation: 'right',
    title: 'Get ongoing price and coverage checks',
  },
];
