import { useEffect, useRef } from 'react';
import * as Sentry from '@sentry/react';
import analytics from '../utils/analytics.util.ts';
import type { Integrations } from '../types/integrations.type.ts';
import { isErrorToSkip } from '../utils/error.util.ts';

function initAllIntegrations(integrations: Integrations): void {
  const { segment, sentry } = integrations;
  if (segment?.enabled && segment.key) {
    analytics.init(segment.key);
  }

  if (sentry?.enabled && sentry.dsn && sentry.environment) {
    Sentry.init({
      dsn: sentry.dsn,
      environment: sentry.environment,
      release: import.meta.env.VITE_APP_SENTRY_RELEASE,
      denyUrls: [
        /http:\/\/localhost:\d+/,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
        /^moz-extension:\/\//i,
      ],
      integrations: [Sentry.httpClientIntegration(), Sentry.extraErrorDataIntegration()],
      initialScope: {
        tags: { sender: 'browser' },
      },
      beforeSend: (event, hint) => {
        if (isErrorToSkip(hint.originalException)) {
          return null;
        }
        return event;
      },
    });
  }
}

function useAnalytics(integrations: Integrations): void {
  const readyRef = useRef<boolean>();
  const someIntegrationEnabled = integrations.segment?.enabled || integrations.sentry?.enabled;

  useEffect(() => {
    if (import.meta.env.PROD && someIntegrationEnabled && !readyRef.current) {
      try {
        readyRef.current = true;
        initAllIntegrations(integrations);
      }
      catch (error) {
        console.error('Failed to load analytics', error);
      }
    }
  }, [integrations, someIntegrationEnabled]);
}

export default useAnalytics;
