import { format } from 'date-fns';

const yyyyMMDDPattern = /(\d{4})-(\d{2})-(\d{2})/;
const mmDDYYYYPattern = /(\d{2})-(\d{2})-(\d{4})/;

const yearInMs = 3.15576e10;
export function getAge(date: string): number | string {
  const result = Math.floor((Date.now() - +new Date(date)) / yearInMs);
  if (Number.isNaN(result))
    return date;

  return result;
}

export function parseDateValue(value: string): string {
  // YYYY-MM-DD to MM-DD-YYYY
  if (!value.match(yyyyMMDDPattern))
    return value;

  return value.replace(yyyyMMDDPattern, '$2-$3-$1');
}

export function normalizeDateValue(value: string): string {
  // MM-DD-YYYY to YYYY-MM-DD
  if (!value.match(mmDDYYYYPattern))
    return value;

  return value.replace(mmDDYYYYPattern, '$3-$1-$2');
}

export function formatDateValue(value: string): string {
  // YYYY-MM-DD to MM/DD/YYYY
  if (!value.match(yyyyMMDDPattern))
    return value;

  return value.replace(yyyyMMDDPattern, '$2/$3/$1');
}

export function formatDateTimeValue(value: string): string {
  return format(new Date(value), 'MM/dd/yyyy \'at\' hh:mm a');
}

export function getDateFromTodayByDays(days: string): Date {
  return new Date(new Date(new Date()).setDate(new Date().getDate() + Number.parseInt(days)));
}

export function isMoreThanOneWeekOld(date?: string): boolean {
  if (!date)
    return false;

  const today = new Date();
  const oneWeekAgo = new Date(today.setDate(today.getDate() - 7));
  return new Date(date) < oneWeekAgo;
}
