import { css } from '@emotion/react';
import { mq } from '../../../styles/media-queries';

export const checkInCardCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  text-align: center;
  padding: 32px;

  ${mq[2]} {
    padding: 80px;
    text-align: start;
  }
`;

export const arrowCSS = css`
  position: relative;
  margin: 0 auto;
  bottom: -20px;
  z-index: 1;
  height: 99px;

  ${mq[2]} {
    bottom: -50px;
    height: 100%;

  }
`;

export const checkInTitleCSS = css`
  font-size: 24px;
  line-height: 32px;
  margin: 0 0 20px;

  ${mq[2]} {
    font-size: 40px;
    line-height: 52px;
  }
`;

export const checkInDescriptionCSS = css`
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 40px;

  ${mq[2]} {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const checkInButtonCSS = css`
  width: 100%;
  max-width: 311px;
`;

export const ellipseCSS = css`
  position: absolute;
  right: 37px;
  top: 42px;
  display: none;

  ${mq[1]} {
    display: block;
  }
`;

export const triangleCSS = css`
  position: absolute;
  left: 32px;
  bottom: 32px;
  display: none;

  ${mq[1]} {
    display: block;
  }
`;
