import { type FC, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { isAxiosError } from 'axios';
import { useIsMutating } from '@tanstack/react-query';
import Button from '../../../shared/components/Button/Button';
import Question from '../../../components/Question/Question';
import ROUTES from '../../../constants/routes';
import useCreateAccount from '../../../api/account/use-create-account';
import Notification from '../../../shared/components/Notification/Notification.tsx';
import useSettingsQuery from '../../../api/settings/use-settings-query.ts';
import useSegment from '../../../hooks/use-segment.ts';
import { AnalyticEventStatus } from '../../../types/analytics.type.ts';
import MUTATION_CACHE_KEYS from '../../../constants/mutation-cache-keys.ts';
import { ANALYTICS_SESSION_GID } from '../../../constants/browser-storage.ts';
import useDisclosuresQuery from '../../../api/disclosures/use-disclosures-query.ts';
import ReplacedText from '../../../shared/components/ReplacedText/ReplacedText.tsx';
import { useDynamicTextReplacements } from '../../../hooks/use-dynamic-text-replacements.tsx';
import type { SignUpFormProps, SignUpFormState } from './SignUpForm.type';
import {
  customButtonCSS,
  descriptionCSS,
  formInnerCSS,
  paragraphCSS,
  titleCSS,
} from './SignUpForm.style';
import { SIGN_UP_FORM_SCHEMA } from './SignUpForm.util.tsx';

const SignUpForm: FC<SignUpFormProps> = ({ customCSS }) => {
  const sessionGid = localStorage.getItem(ANALYTICS_SESSION_GID);

  const navigate = useNavigate();
  const { mutateAsync: createAccount, isPending: isAccountCreationPending } = useCreateAccount();
  const { data: settingsData } = useSettingsQuery();
  const dynamicTextReplacements = useDynamicTextReplacements(settingsData?.phone_number);
  const { data: disclosuresData } = useDisclosuresQuery('sign-up');
  const [showError, setShowError] = useState(false);
  const { track } = useSegment();
  const isMutatingMagicLink = useIsMutating({ mutationKey: [MUTATION_CACHE_KEYS.magicLink] });

  const methods = useForm<SignUpFormState>({
    shouldUnregister: false,
    shouldFocusError: true,
    mode: 'onTouched',
  });

  const ctaButtonText = 'Sign Up';

  const onSubmit: SubmitHandler<SignUpFormState> = async (data) => {
    try {
      const payload = sessionGid ? { ...data, session_gid: sessionGid } : data;
      const res = await createAccount(payload);
      track('Sign Up Form Submitted', {
        status: AnalyticEventStatus.Success,
      });
      navigate(`${ROUTES.checkEmail}?email=${res.account.email}`, { state: { email: data.email } });
    }
    catch (e) {
      if (isAxiosError(e) && e.response && e.response.status === 422) {
        const errorData = e.response.data.data;

        if (['email', 'address'].includes(errorData.key)) {
          track('Sign Up Form Submitted', {
            status: AnalyticEventStatus.Error,
            error_key: errorData.key,
          });
          return methods.setError(errorData.key, { type: 'manual', message: errorData.message });
        }
        else {
          track('Sign Up Form Submitted', {
            status: AnalyticEventStatus.Error,
            error_key: 'network_error',
          });
        }
      }
      else {
        track('Sign Up Form Submitted', {
          status: AnalyticEventStatus.Error,
          error_key: 'ui_error',
        });
      }

      setShowError(true);
    }
  };

  return (
    <div css={customCSS} id="scroll-to-sign-up-form">
      <h2 css={titleCSS}>Keep your home insurance on track</h2>
      <p css={descriptionCSS}>
        Automatic rate monitoring, coverage insights, and personalized
        recommendations — all in one place. Sign up for free.
      </p>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div css={formInnerCSS}>
            {SIGN_UP_FORM_SCHEMA.map((questionProps) => {
              return <Question key={questionProps.questionKey} {...questionProps} />;
            })}
          </div>

          <Button
            type="submit"
            size="large"
            fullWidth
            isLoading={isAccountCreationPending || !!isMutatingMagicLink}
          >
            {ctaButtonText}
          </Button>
        </form>
      </FormProvider>
      {disclosuresData?.disclosures.map(disclosure => (
        <ReplacedText
          customCSS={paragraphCSS}
          replacements={{ ...dynamicTextReplacements, cta_text: ctaButtonText }}
          template={disclosure.content}
          key={disclosure.content}
        />
      ))}

      <Button variant="text" onClick={() => navigate(ROUTES.signIn)} customCSS={customButtonCSS}>
        Already have an account? Log in Here.
      </Button>

      <Notification
        open={showError}
        message="Something went wrong. Please try again later."
        autoHideDuration={10000}
        onClose={() => setShowError(false)}
      />
    </div>
  );
};

export default SignUpForm;
