import { getTargetPath } from '../../utils/routes.util.ts';

export function canAuthenticate(
  token?: string | null,
  accountGid?: string | null,
  personGid?: string | null,
  sessionGid?: string | null,
  isAccountFetched?: boolean,
  hasAccountData?: boolean,
  authSend?: boolean,
): boolean {
  if (accountGid) {
    return Boolean(!authSend && sessionGid && isAccountFetched && !hasAccountData && token);
  }
  else if (personGid) {
    return Boolean(!authSend && sessionGid && token);
  }
  else {
    return false;
  }
}

export function getVerifiedUserNavigationUrl(
  gid: string,
  targetPage: string | null,
  newAccount: string | null,
): string {
  if (newAccount === 'true') {
    return `${getTargetPath(gid, targetPage)}?new_account=true`;
  }
  return getTargetPath(gid, targetPage);
}
