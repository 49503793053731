import { type FC, useEffect, useRef, useState } from 'react';

import { isAxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import useSessionRefresh from '../../api/authentification/use-session-refresh.ts';
import useSignOut from '../../api/authentification/use-sign-out.ts';
import useSessionTimeoutStore from '../../context/session-timeout-context.ts';
import Modal from '../../shared/components/Modal/Modal.tsx';
import { ModalSize } from '../../shared/components/Modal/Modal.type.ts';
import TrackOnInit from '../TrackOnInit/TrackOnInit.tsx';
import FullPageLoader from '../../shared/components/FullPageLoader/FullPageLoader.tsx';
import { ANALYTICS_SESSION_GID, LOGGED_OUT_SESSION_GID } from '../../constants/browser-storage.ts';
import ROUTES from '../../constants/routes.ts';
import isDemo from '../../utils/is-demo.util.ts';
import { DEFAULT_SESSION_TIMEOUT_MIL_SEC, SESSION_TIMEOUT_WAITING_SEC } from './SessionExpiredModal.util.ts';

const SessionExpiredModal: FC = () => {
  const { mutateAsync: sessionRefresh } = useSessionRefresh();
  const { mutateAsync: signOutAccount } = useSignOut();
  const lastUserInteractionTime = useSessionTimeoutStore(s => s.lastUserInteractionTime);
  const [secondsLeft, setSecondsLeft] = useState<null | number>(null);
  const [isLoading, setIsLoading] = useState(false);
  const accountSignedOutRef = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    const call = async () => {
      accountSignedOutRef.current = true;
      setIsLoading(true);
      try {
        await signOutAccount();
        navigate(ROUTES.signIn, { replace: true });
      }
      catch (error) {
        if (isAxiosError(error) && error.response?.status === 401) {
          const sessionGid = localStorage.getItem(ANALYTICS_SESSION_GID);
          sessionStorage.setItem(LOGGED_OUT_SESSION_GID, sessionGid || '');
          localStorage.removeItem(ANALYTICS_SESSION_GID);
          navigate(ROUTES.signIn, { replace: true });
        }
      }
    };

    if (typeof secondsLeft === 'number' && secondsLeft < 1 && !accountSignedOutRef.current && !isDemo()) {
      call();
    }
  }, [secondsLeft, signOutAccount, navigate]);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = Date.now();
      const elapsed = currentTime - lastUserInteractionTime;
      const left = DEFAULT_SESSION_TIMEOUT_MIL_SEC - elapsed;
      const leftSec = Math.trunc(left / 1000);

      if (leftSec <= SESSION_TIMEOUT_WAITING_SEC) {
        setIsLoading(false);
        setSecondsLeft(leftSec);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [lastUserInteractionTime]);

  const handleSignOut = () => {
    setIsLoading(true);
    signOutAccount();
  };

  const handleSessionRefresh = async (): Promise<void> => {
    setIsLoading(true);
    await sessionRefresh();
    setSecondsLeft(null);
  };

  if (isLoading) {
    return <FullPageLoader />;
  }

  if (!secondsLeft) {
    return null;
  }

  return (
    <>
      <Modal
        isOpen
        title="Your session is about to expire"
        blurBackdrop
        size={ModalSize.Small}
        actionButtons={[
          {
            text: 'Log Off',
            variant: 'secondary',
            isLoading,
            onClick: handleSignOut,
          },
          {
            text: 'Stay Logged In',
            isLoading,
            onClick: handleSessionRefresh,
          },
        ]}
      >
        For security purposes, inactive sessions are automatically close.
        Your session will close in {secondsLeft} seconds.
      </Modal>
      <TrackOnInit eventName="Session Expiration Warning Viewed" />
    </>
  );
};

export default SessionExpiredModal;
