import { StrictMode } from 'react';

import { createRoot } from 'react-dom/client';

// TODO remove this package later
// import '../node_modules/normalize.css/normalize.css';

import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import App from './App';
import THEME from './styles/theme.ts';
import { QUERY_CLIENT_CONFIG } from './constants/queryClientConfig.ts';
import isDemo from './utils/is-demo.util.ts';

const muiTheme = createTheme({
  typography: {
    fontFamily: THEME.typography.fontFamily,
    fontSize: THEME.typography.fontSize,
  },
});
const queryCache = new QueryClient(QUERY_CLIENT_CONFIG);

const root = createRoot(document.getElementById('root') as HTMLElement);

async function enableMocking() {
  try {
    if (!isDemo()) {
      return;
    }

    const { worker } = await import('./mocks/browser.ts');

    return worker.start();
  }
  catch (error) {
    console.error('Failed to enable mocking:', error);
  }
}

enableMocking().then(() => {
  root.render(
    <StrictMode>
      <ThemeProvider theme={muiTheme}>
        <QueryClientProvider client={queryCache}>
          <App />
        </QueryClientProvider>
      </ThemeProvider>
    </StrictMode>,
  );
});
