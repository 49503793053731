import { z } from 'zod';
import { AccountCreationMethod } from '../../types/account.type.ts';

export const AddressSchema = z.object({
  line1: z.string(),
  line2: z.string().nullish(),
  state: z.string(),
  city: z.string(),
  zip: z.string(),
  full: z.string().optional(),
});

export const SignUpPayloadSchema = z.object({
  email: z.string().email(),
  first_name: z.string(),
  last_name: z.string(),
  date_of_birth: z.string().date().optional(),
  phone: z.string().nullable(),
  address: AddressSchema,
  session_gid: z.string().optional(),
});

export const AccountSchema = z.object({
  gid: z.string(),
  person_gid: z.string(),
  first_name: z.string(),
  last_name: z.string().optional(),
  date_of_birth: z.string().date().optional(),
  email: z.string().email(),
  phone: z.string().nullable(),
  verified: z.boolean(),
  verified_at: z.string().datetime({ offset: true }).nullable(), // unix time in seconds
  address: AddressSchema.optional(),
  engagement_gid: z.string().nullable(), // TODO: is it possible, or this is an issue?
  profile_completed_at: z.string().datetime({ offset: true }).nullable(), // unix time in seconds
  available_authorization_attempts_count: z.number(),
  creation_method: z.nativeEnum(AccountCreationMethod).nullable(), // TODO: Remove nullable
  test_mode: z.boolean(),
  is_policyholder: z.boolean(),
});

export const AccountResponseSchema = z.object({
  account: AccountSchema,
  person_has_core_bundle_interest: z.union([z.literal('yes'), z.literal('no'), z.null()]).optional(), // TODO: Move to a separate API
});

export const AccountUnauthorizedResponseSchema = z.object({
  account: z.object({
    email: z.string().email(),
    session_gid: z.string().optional(),
  }),
});
