import { css } from '@emotion/react';

import THEME from '../../../styles/theme.ts';

export const labelCSS = css`
  display: flex;
  color: ${THEME.color.black};
  vertical-align: middle;
  align-items: center;
`;
