import { useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import useSessionTimeoutStore from '../../context/session-timeout-context.ts';
import type { HTTPResponse } from '../../types/api.type';
import api from '../api';
import type { PremiumHistoryResponse } from '../../types/history.type.ts';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys.ts';
import { checkResponseType } from '../../utils/error.util.ts';
import { PremiumHistoryResponseSchema } from './history.schema.ts';

export default function usePremiumHistoryQuery(gid: string, enabled?: boolean) {
  const updateLastUserInteractionTime = useSessionTimeoutStore(s => s.updateLastUserInteractionTime);

  return useQuery({
    queryKey: [QUERY_CACHE_KEYS.premiumHistory, gid],
    enabled,
    queryFn: async () =>
      await api
        .get<HTTPResponse<PremiumHistoryResponse>>(API_PATH.premiumHistory().replace(':gid', gid))
        .then((res) => {
          if (res.session.refreshed_at) {
            updateLastUserInteractionTime(res.session.refreshed_at * 1000);
          }

          return checkResponseType<PremiumHistoryResponse>(res.data, PremiumHistoryResponseSchema);
        }),
  });
}
