import './VerticalPolicyCard.scss';
import React, { memo } from 'react';
import classNames from 'classnames';
import { UNKNOWN } from '../../constants/quotes';
import type { Home, Person, Policy } from '../../services/rateReviews';
import { Card, CardContent } from '../Card/Card';

interface Props {
  policy?: Policy;
  person?: Person;
  home?: Home;
  className?: string;
}

const VerticalPolicyCard: React.FunctionComponent<Props> = ({ policy, person, home, className }) => (
  <Card className={classNames('c-vertical-policy-card', className)}>
    <CardContent className="c-vertical-policy-card__content">
      <div className="u-mb-2">
        <span className="o-paragraph--large">
          <strong>Current Policy</strong>
        </span>
      </div>
      <div className="c-vertical-policy-card__customer u-mb-3">
        <p className="o-paragraph--bold u-mb-1">
          {person?.first_name}
          {' '}
          {person?.last_name}
        </p>
        <div>{home?.address}</div>
      </div>

      <div className="c-vertical-policy-card__carrier">
        <div>{policy?.carrier_name}</div>
        <span className="c-vertical-policy-card__carrier-note">(current carrier)</span>
      </div>
      <div className="c-vertical-policy-card__prices-container">
        <div className="c-vertical-policy-card__price c-vertical-policy-card__premium">
          <span className="c-vertical-policy-card__label">
            <span className="c-vertical-policy-card__annual">Annual </span>
            Premium
          </span>
          <span className="c-vertical-policy-card__value">{policy?.premium || UNKNOWN}</span>
          <span className="c-vertical-policy-card__per-year"> per year</span>
        </div>
        <div className="c-vertical-policy-card__price">
          <span className="c-vertical-policy-card__label">Deductible</span>
          <span className="c-vertical-policy-card__value">{policy?.deductible || UNKNOWN}</span>
        </div>
        <div className="c-vertical-policy-card__price">
          <span className="c-vertical-policy-card__label">Dwelling</span>
          <span className="c-vertical-policy-card__value">{policy?.dwelling || UNKNOWN}</span>
        </div>
      </div>

      <div className="c-vertical-policy-card__renewal">
        <span className="c-vertical-policy-card__label">Expiration</span>
        <span className="c-vertical-policy-card__value">
          <span className="c-vertical-policy-card__renewal-date">{policy?.expiration_date || UNKNOWN}</span>
        </span>
      </div>
    </CardContent>
  </Card>
);

export default memo(VerticalPolicyCard);
