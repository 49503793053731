import type { FC } from 'react';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import PageContainer from '../../components/PageContainer/PageContainer.tsx';
import SectionHeading from '../../components/SectionHeading/SectionHeading.tsx';
import {
  columnsWrapperCSS,
  customSectionHeadingCSS,
  leftColumnCSS,
  pageHeadingCSS,
  pageSubheadingCSS,
  rightColumnCSS,
} from '../../styles/layout.style.ts';
import SuggestedPolicy from '../../components/SuggestedPolicy/SuggestedPolicy.tsx';
import FactorsList from '../../components/FactorsList/FactorsList.tsx';
import { ScoreType } from '../../types/score.type.ts';
import MaticContent from '../../components/MaticContent/MaticContent.tsx';
import useResponsive from '../../hooks/use-responsive.ts';
import useTrackPage from '../../hooks/use-track-page.ts';
import { AnalyticPageKey } from '../../types/analytics.type.ts';
import CarrierOptionsList from './CarrierOptionsList/CarrierOptionsList.tsx';
import QuotesOverTimeHistory from './QuotesOverTimeHistory/QuotesOverTimeHistory.tsx';
import FooterCopy from './FooterCopy/FooterCopy.tsx';
import { carrierOptionsScrollPointCSS } from './CarrierOptionsLayout.style.ts';

const CarrierOptionsLayout: FC = () => {
  const { state: locationState } = useLocation();
  const carriersListScrollPointRef = useRef<HTMLDivElement>(null);
  const isCarrierListScrolled = useRef(false);
  const { isMobile } = useResponsive();

  useTrackPage(AnalyticPageKey.CarrierOptions);

  const scrollToList = locationState?.scrollToList as boolean;

  useEffect(() => {
    if (scrollToList && carriersListScrollPointRef.current && !isCarrierListScrolled.current) {
      isCarrierListScrolled.current = true;
      window.history.replaceState({}, '');

      setTimeout(() => {
        carriersListScrollPointRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }
  }, [scrollToList]);

  const handleShowMoreQuotesClick = () => {
    carriersListScrollPointRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <PageContainer pageKey={AnalyticPageKey.CarrierOptions} footerCopy={<FooterCopy />} showBackButton>
      <h1 css={pageHeadingCSS}>Carrier Options</h1>
      <p css={pageSubheadingCSS}>View available carrier quote options.</p>

      <div css={columnsWrapperCSS}>
        <div css={leftColumnCSS}>
          <QuotesOverTimeHistory />

          <SectionHeading
            title="Risk Factors impacting carrier availability"
            subtitle="Below are some of the factors that carriers may use when determining whether to quote a policy."
          />
          <FactorsList
            page={AnalyticPageKey.CarrierOptions}
            scoreType={ScoreType.Risk}
            noFactorsButtonLabel="Unlock Your Quote Factors"
          />

          <div css={carrierOptionsScrollPointCSS} ref={carriersListScrollPointRef} />
          <SectionHeading title="Available Quotes" />
          <CarrierOptionsList />

          {!isMobile && (
            <>
              <SectionHeading title="Home Insurance Resources" />
              <MaticContent page={AnalyticPageKey.CarrierOptions} />
            </>
          )}
        </div>

        <div css={rightColumnCSS}>
          <SectionHeading title="Your personalized home quote" customCSS={customSectionHeadingCSS} />
          <SuggestedPolicy page={AnalyticPageKey.CarrierOptions} onShowMoreQuotesClick={handleShowMoreQuotesClick} />
          {isMobile && (
            <>
              <SectionHeading title="Home Insurance Resources" />
              <MaticContent page={AnalyticPageKey.CarrierOptions} />
            </>
          )}
        </div>
      </div>

    </PageContainer>
  );
};

export default CarrierOptionsLayout;
