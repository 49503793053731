import type { Quote } from '../types/quote.type.ts';
import { CoverageKey, DeductibleType, QuoteKind } from '../types/quote.type.ts';

export function getQuoteDeductible(quote: Quote): string | number {
  const dwellingCoverage = quote.coverages?.find(coverage => coverage.key === CoverageKey.Dwelling);

  if (!dwellingCoverage || !dwellingCoverage.deductible_value) {
    return '';
  }

  if (dwellingCoverage.deductible_type === DeductibleType.Percentage && dwellingCoverage.limit_value) {
    return dwellingCoverage.deductible_value * dwellingCoverage.limit_value / 100;
  }

  return dwellingCoverage.deductible_value;
}

export function getQuoteDwelling(quote: Quote): string | number {
  const dwellingCoverage = quote.coverages?.find(coverage => coverage.key === CoverageKey.Dwelling);

  if (!dwellingCoverage || !dwellingCoverage.limit_value) {
    return '';
  }

  return dwellingCoverage.limit_value;
}

export function hasIntegratedQuote(quotes?: Quote[]): boolean {
  return quotes?.some(quote => quote.kind !== QuoteKind.NonIntegrated) || false;
}
