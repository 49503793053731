import { type FC, Fragment } from 'react';

import usePersonAssetsQuery from '../../../api/proposal/use-person-assets-query';
import usePublicRecentPolicyQuery from '../../../api/proposal/use-public-recent-policy-query';
import Comment from '../../../components/Comment/Comment';
import ProposalCard from '../../../components/ProposalCard/ProposalCard';
import { CoverageKey, PolicyType, type QuoteCoverage } from '../../../types/quote.type';
import { FS_SENSITIVE_DATA_CLASS } from '../../../constants/full-story.ts';
import { moneyFormatterWithoutLastZeros, policyTypeLabels } from '../ProposalLayout.util.ts';
import {
  additionalCoveragesExcludedCSS,
  additionalCoveragesExcludedTextCSS,
  additionalCoveragesIncludedCSS,
  addressLineCSS,
  boldTextCSS,
  carrierLineCSS,
  containerCSS,
  coverageLineCSS,
  dividerLineCSS,
  excludedCoveragesDescriptionCSS,
  headerCSS,
  headerLineCSS,
} from './ProposalHomeQuoteInfo.style';
import type { ProposalHomeQuoteInfoProps } from './ProposalHomeQuoteInfo.type';
import {
  CONTENT_COMMENTS,
  formatDeductibleBasedOnDwellingValue,
  getAdditionalCoverageTitle,
  getDeductibleByCoverageKey,
  getDeductibleComment,
  getDwellingComment,
  getExcludedAdditionalCoverages,
  getIncludedAdditionalCoverages,
  getLimitByCoverageKey,
} from './ProposalHomeQuoteInfo.util';

const ProposalHomeQuoteInfo: FC<ProposalHomeQuoteInfoProps> = ({ quote, personGid }) => {
  const { data: assets } = usePersonAssetsQuery({ personGid, assetGids: quote.assets_gids });
  const { data: recentPolicy } = usePublicRecentPolicyQuery({
    personGid,
    assetGid: quote.assets_gids?.[0],
    policyTypes: [quote.policy_type],
  });

  const home = assets?.homes[0];
  const coverages = quote.coverages;

  const dwellingCoverage = coverages?.find(coverage => coverage.key === CoverageKey.Dwelling);
  const windHailCoverage = coverages?.find(coverage => coverage.key === CoverageKey.WindHail);
  const windHailDeductible = getDeductibleByCoverageKey(coverages, CoverageKey.WindHail);
  const hurricaneCoverage = coverages?.find(coverage => coverage.key === CoverageKey.Hurricane);
  const hurricaneDeductible = getDeductibleByCoverageKey(coverages, CoverageKey.Hurricane);
  const dwelling = getLimitByCoverageKey(coverages, CoverageKey.Dwelling);
  const otherStructures = getLimitByCoverageKey(coverages, CoverageKey.OtherStructures);
  const personalProperty = getLimitByCoverageKey(coverages, CoverageKey.PersonalProperty);
  const lossOfUse = getLimitByCoverageKey(coverages, CoverageKey.LossOfUse);
  const personalLiability = getLimitByCoverageKey(coverages, CoverageKey.PersonalLiability);
  const medicalPayments = getLimitByCoverageKey(coverages, CoverageKey.MedicalPayments);
  const householdFurnishings = getLimitByCoverageKey(coverages, CoverageKey.HouseholdFurnishings);
  const fairRentalValue = getLimitByCoverageKey(coverages, CoverageKey.FairRentalValue);
  const additionalCoveragesIncluded = getIncludedAdditionalCoverages(coverages);
  const additionalCoveragesExcluded = getExcludedAdditionalCoverages(coverages);
  const deductibleComment = getDeductibleComment(quote, recentPolicy);
  const dwellingComment = getDwellingComment(quote, recentPolicy);

  const policyType = quote.policy_type;
  const isInvestment = policyType === PolicyType.Fire;
  const isRenters = policyType === PolicyType.Renters;
  const isCondo = policyType === PolicyType.Condo;

  return (
    <ProposalCard isTextCard>
      <div css={containerCSS}>
        <p css={headerCSS}>{policyTypeLabels[quote.policy_type] || 'Home'} coverage and pricing summary</p>
        <p css={addressLineCSS} className={FS_SENSITIVE_DATA_CLASS.mask}>{home?.address?.full}</p>
        <div css={carrierLineCSS}>
          <p css={boldTextCSS}>Carrier</p>
          <img src={quote.carrier.logo_url!} alt={quote.carrier.name!} width="120" />
        </div>
        <div css={headerLineCSS}>
          <p css={boldTextCSS}>Deductibles</p>
        </div>
        {dwellingCoverage && (
          <>
            <div css={coverageLineCSS}>
              <p>Deductible</p>
              <p>{formatDeductibleBasedOnDwellingValue(dwellingCoverage, dwellingCoverage?.limit_value)}</p>
            </div>
            <Comment content={deductibleComment} />
          </>
        )}
        {windHailCoverage && windHailDeductible !== 'Not included' && (
          <div css={coverageLineCSS}>
            <p>Wind/Hail deductible</p>
            <p>{formatDeductibleBasedOnDwellingValue(windHailCoverage, dwellingCoverage?.limit_value)}</p>
          </div>
        )}
        {hurricaneCoverage && hurricaneDeductible !== 'Not included' && (
          <div css={coverageLineCSS}>
            <p>Hurricane</p>
            <p>{formatDeductibleBasedOnDwellingValue(hurricaneCoverage, dwellingCoverage?.limit_value)}</p>
          </div>
        )}
        <div css={headerLineCSS}>
          <p css={boldTextCSS}>Matic recommended coverages</p>
          <p css={boldTextCSS}>Limit</p>
        </div>
        {dwelling && !isRenters && (
          <>
            <div css={coverageLineCSS}>
              <p>Dwelling</p>
              <p>{dwelling}</p>
            </div>
            {dwellingComment && <Comment content={dwellingComment} />}
          </>
        )}

        {!(isCondo || isRenters) && otherStructures && (
          <div css={coverageLineCSS}>
            <p>Other structures</p>
            <p>{otherStructures}</p>
          </div>
        )}

        {!isInvestment && (
          <>
            {personalProperty && (
              <div css={coverageLineCSS}>
                <p>Personal property</p>
                <p>{personalProperty}</p>
              </div>
            )}
            {lossOfUse && (
              <div css={coverageLineCSS}>
                <p>Loss of use</p>
                <p>{lossOfUse}</p>
              </div>
            )}
          </>
        )}

        {isInvestment && (
          <>
            <div css={coverageLineCSS}>
              <p>Household furnishings</p>
              <p>{householdFurnishings}</p>
            </div>
            <div css={coverageLineCSS}>
              <p>Fair rental value</p>
              <p>{fairRentalValue}</p>
            </div>
          </>
        )}
        {personalLiability && (
          <div css={coverageLineCSS}>
            <p>{isInvestment ? 'Premises liability' : 'Personal liability'}</p>
            <p>{personalLiability}</p>
          </div>
        )}
        {medicalPayments && (
          <div css={coverageLineCSS}>
            <p>Medical payment (to others)</p>
            <p>{medicalPayments}</p>
          </div>
        )}
        {additionalCoveragesIncluded && additionalCoveragesIncluded.length > 0 && (
          <>
            <p css={additionalCoveragesIncludedCSS}>Additional coverages included</p>
            {additionalCoveragesIncluded.map((coverage) => {
              const getAdditionalCoverageValue = (coverage: QuoteCoverage) => {
                if (coverage.key === CoverageKey.Hurricane || coverage.key === CoverageKey.ExtendedReplacementCost) {
                  return formatDeductibleBasedOnDwellingValue(coverage, dwellingCoverage?.limit_value);
                }
                return coverage.limit_description;
              };

              return (
                <Fragment key={coverage.key}>
                  <div css={coverageLineCSS}>
                    <p>{getAdditionalCoverageTitle(coverage.key)}</p>
                    <p>{getAdditionalCoverageValue(coverage)}</p>
                  </div>
                  {coverage.key === CoverageKey.WaterBackup && <Comment content={CONTENT_COMMENTS.waterBackup} />}
                </Fragment>
              );
            })}
          </>
        )}
        {additionalCoveragesExcluded && additionalCoveragesExcluded.length > 0 && (
          <>
            <div css={additionalCoveragesExcludedCSS}>
              <p css={boldTextCSS}>Excluded coverages</p>
              <p css={additionalCoveragesExcludedTextCSS}>Request agent to include as needed</p>
            </div>
            {additionalCoveragesExcluded.map(coverage => (
              <div css={coverageLineCSS} key={coverage.key}>
                <p>{getAdditionalCoverageTitle(coverage.key)}</p>
                <p css={excludedCoveragesDescriptionCSS}>
                  {coverage.deductible_description || coverage.limit_description}
                </p>
              </div>
            ))}
          </>
        )}

        <div css={dividerLineCSS} />
        <div css={coverageLineCSS}>
          <p css={headerCSS}>Yearly premium</p>
          <p css={headerCSS}>{moneyFormatterWithoutLastZeros(quote.premium)}</p>
        </div>
      </div>
    </ProposalCard>
  );
};

export default ProposalHomeQuoteInfo;
