import { useQuery } from '@tanstack/react-query';

import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import useSessionTimeoutStore from '../../context/session-timeout-context.ts';
import type { HTTPResponse } from '../../types/api.type';
import api from '../api';
import type { AccountResponse } from '../../types/account.type.ts';
import { checkResponseType } from '../../utils/error.util.ts';
import { AccountResponseSchema } from './account-query.schema.ts';

export default function useAccountQuery(gid?: string | null, throwOnError = true, refetchOnWindowFocus = true) {
  const updateLastUserInteractionTime = useSessionTimeoutStore(s => s.updateLastUserInteractionTime);

  return useQuery({
    queryKey: [QUERY_CACHE_KEYS.account, gid],
    enabled: !!gid,
    throwOnError,
    refetchOnWindowFocus,
    refetchOnMount: true,
    queryFn: async () =>
      await api.get<HTTPResponse<AccountResponse>>(API_PATH.account.replace(':gid', gid as string)).then((res) => {
        if (res.session.refreshed_at) {
          updateLastUserInteractionTime(res.session.refreshed_at * 1000);
        }

        return checkResponseType<AccountResponse>(res.data, AccountResponseSchema);
      }),
  });
}
