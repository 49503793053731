import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import PageContainer from '../../components/PageContainer/PageContainer';
import useTrackPage from '../../hooks/use-track-page.ts';
import { AnalyticPageKey } from '../../types/analytics.type.ts';
import useAccountQuery from '../../api/account/use-account-query.ts';
import type { DefaultRouteParams } from '../../types/route-params.type.ts';
import FooterCopy from './FooterCopy/FooterCopy.tsx';
import ComingSoon from './ComingSoon/ComingSoon.tsx';
import MyPolicies from './MyPolicies/MyPolicies.tsx';

const PoliciesLayout: FC = () => {
  const { gid } = useParams() as DefaultRouteParams;
  const { data: accountData } = useAccountQuery(gid);

  useTrackPage(AnalyticPageKey.Policies);

  return (
    <PageContainer pageKey={AnalyticPageKey.Policies} footerCopy={<FooterCopy />}>
      {accountData?.account.is_policyholder
        ? <ComingSoon />
        : <MyPolicies />}
    </PageContainer>
  );
};

export default PoliciesLayout;
