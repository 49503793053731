import type { ReactElement } from 'react';

import type { GridWidth } from '../../types/form.type.ts';
import type { Validation } from '../../types/validation.type.ts';

export interface QuestionVariant {
  label: string;
  value: string | boolean;
  description?: string | null;
  icon_url?: string | null;
}

export enum QuestionType {
  Text = 'text',
  Date = 'date',
  Phone = 'phone',
  Email = 'email',
  Number = 'number',
  Address = 'address',
}

export interface QuestionProps {
  questionKey: string;
  type?: QuestionType;
  label: string | ReactElement;
  hideLabel?: boolean;
  description?: string;
  hint?: string;
  placeholder?: string;
  icon?: string;
  variants?: QuestionVariant[];
  validations?: Validation[];
  mask?: string;
  isDisabled?: boolean;
  gridWidth?: GridWidth | null;
  dense?: boolean;
  url?: string;
  trackingForbidden?: boolean;
}

export type SpecificQuestionProps = Omit<QuestionProps, 'type'>;
