import { createContext } from 'react';

import * as ActionCable from '../services/ActionCable.ts';

let lastConsumer: ActionCable.Consumer | null = null;

function createConsumer() {
  const websocketEndpoint = import.meta.env.DEV
    ? `${import.meta.env.VITE_APP_WSS_URL}`
    : `wss://ws-${window.location.host}`;

  return ActionCable.createConsumer(websocketEndpoint);
}

export function setupCableConsumer() {
  if (lastConsumer) {
    lastConsumer.disconnect();
    lastConsumer = createConsumer();
    return lastConsumer;
  }

  lastConsumer = createConsumer();

  return lastConsumer;
}

export const ActionCableContext = createContext<ActionCable.Consumer | null>(null);
