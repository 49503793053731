import { useQuery } from '@tanstack/react-query';

import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import useSessionTimeoutStore from '../../context/session-timeout-context.ts';
import type { HTTPResponse } from '../../types/api.type';
import type { DisclosureResponse } from '../../types/disclosures.type.ts';
import api from '../api';
import { checkResponseType } from '../../utils/error.util.ts';
import { DisclosureResponseSchema } from './disclosures.schema.ts';

function useDisclosuresQuery(gid: string) {
  const updateLastUserInteractionTime = useSessionTimeoutStore(s => s.updateLastUserInteractionTime);

  return useQuery({
    queryKey: [QUERY_CACHE_KEYS.disclosures, gid],
    queryFn: async () =>
      await api.get<HTTPResponse<DisclosureResponse>>(API_PATH.disclosures).then((res) => {
        if (res.session.refreshed_at) {
          updateLastUserInteractionTime(res.session.refreshed_at * 1000);
        }

        return checkResponseType<DisclosureResponse>(res.data, DisclosureResponseSchema);
      }),
  });
}

export default useDisclosuresQuery;
