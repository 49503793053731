import { css } from '@emotion/react';
import type { SerializedStyles } from '@emotion/react';
import { formInputFocusOutlineCSS } from '../../../../styles/form.style';
import { mq } from '../../../../styles/media-queries';
import THEME from '../../../../styles/theme';
import { RADIO_BUTTON_CLASSNAMES } from './RadioButton.util';

export function containerCSS(isDisabled?: boolean): SerializedStyles {
  return css`
  position: relative;
  background-color: ${isDisabled ? THEME.color.gray10 : THEME.color.white};
  cursor: pointer;
  display: block;
  height: auto;
  ${isDisabled
  && css`
    pointer-events: none;
  `};
`;
}

export function inputCSS(hasError?: boolean): SerializedStyles {
  return css`
  height: 0;
  outline: none;
  position: absolute;
  width: 0;

  &:checked {
    + .${RADIO_BUTTON_CLASSNAMES.inner} {
      ${`.${RADIO_BUTTON_CLASSNAMES.dot}`}:before { 
        background-color: ${THEME.color.azure50};
      }
      
      .${RADIO_BUTTON_CLASSNAMES.image} {
        opacity: 1;
      }
    }
  }

  &:focus {
    + .${RADIO_BUTTON_CLASSNAMES.inner} {
      border-color: ${THEME.color.black};
      box-shadow: inset 0 0 0 1px transparent;

      &:before {
        ${formInputFocusOutlineCSS};
        border-color: ${hasError ? THEME.color.errorRed : THEME.color.azure50};
      }
    }
  }
`;
}

export const contentInnerCSS = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const labelContainerCSS = css`
  padding-left: 28px;
`;

export function labelCSS(bold: boolean): SerializedStyles {
  return css`
    font-weight: ${bold ? 700 : 500};
  `;
}

/* Shared styles */

export function radioButtonDotCSS(mobileAdaptive?: boolean): SerializedStyles {
  return css`
    position: absolute;
    content: '';
    border: 1px solid ${THEME.color.black};
    border-radius: 50%;
    height: ${mobileAdaptive ? 14 : 18}px;
    width: ${mobileAdaptive ? 14 : 18}px;
    background-color: ${THEME.color.white};
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      width: ${mobileAdaptive ? 8 : 12}px;
      height: ${mobileAdaptive ? 8 : 12}px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border: 1px solid transparent;
      border-radius: 50%;
    }
    ${mq[0]} {
      height: 18px;
      width: 18px;
      &:before {
        height: 12px;
        width: 12px;
      }
    }
  `;
}
