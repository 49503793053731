import type { z } from 'zod';
import type { DigitalProfileResponseSchema, DigitalProfileSchema } from '../api/digital-profile/digital-profile.schema';

export enum DpCta {
  Score = 'score',
  Quotes = 'quotes',
  CoverageLevel = 'coverage level',
}

export type DigitalProfile = z.infer<typeof DigitalProfileSchema>;
export type DigitalProfileResponse = z.infer<typeof DigitalProfileResponseSchema>;
