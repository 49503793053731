import type { z } from 'zod';
import type { PolicyInfoSchema } from '../api/score/score-query.schema';
import type { FactorSchema, ScoreResponseSchema, ScoreSchema } from '../api/score/score.schema';

export enum FactorLevel {
  Low = 'low',
  Moderate = 'moderate',
  High = 'high',
  Unknown = 'unknown',
}

export enum ScoreType {
  Risk = 'risk',
  Protection = 'protection',
  Replacement = 'replacement',
}

export type PolicyInfo = z.infer<typeof PolicyInfoSchema>;
export type Factor = z.infer<typeof FactorSchema>;
export type Score = z.infer<typeof ScoreSchema>;
export type ScoreResponse = z.infer<typeof ScoreResponseSchema>;
