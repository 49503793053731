import { type FC, useEffect, useRef, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import useMutateAccount from '../../../api/account/use-mutate-account.ts';
import useAccountQuery from '../../../api/account/use-account-query.ts';
import Question from '../../../components/Question/Question.tsx';
import Button from '../../../shared/components/Button/Button.tsx';
import type { DefaultRouteParams } from '../../../types/route-params.type.ts';
import { scrollToError } from '../../../utils/form.util.ts';
import Notification from '../../../shared/components/Notification/Notification.tsx';
import useSegment from '../../../hooks/use-segment.ts';
import { AnalyticEventStatus } from '../../../types/analytics.type.ts';
import { reportAPIExecutionError } from '../../../utils/error.util.ts';
import { PROFILE_FORM_SCHEMA, type ProfileFormProps, type ProfileFormState } from './ProfileForm.type.ts';
import { profileDataHasChanges } from './ProfileForm.util.ts';

import { actionButtonsCSS, formContainerCSS } from './ProfileForm.style.ts';

const ProfileForm: FC<ProfileFormProps> = ({ customCSS }) => {
  const { gid } = useParams() as DefaultRouteParams;
  const { data: accountData } = useAccountQuery(gid);
  const { mutateAsync: updateAccount, isPending: isAccountUpdatePending } = useMutateAccount(gid);
  const [updateNotification, setShowNotification] = useState<string | null>();
  const { track } = useSegment();
  const isReset = useRef(false);

  const methods = useForm<ProfileFormState>({
    shouldUnregister: false,
    shouldFocusError: false,
    mode: 'onTouched',
  });

  const formData = methods.watch();

  const isDataChanged = profileDataHasChanges(formData, accountData?.account || {});

  useEffect(() => {
    if (accountData && !isReset.current) {
      methods.reset({
        first_name: accountData.account.first_name,
        last_name: accountData.account.last_name,
        email: accountData.account.email,
        phone: accountData.account.phone || '',
        date_of_birth: accountData.account.date_of_birth,
      });

      isReset.current = true;
    }
  }, [methods, accountData]);

  const onFormReset = () => {
    methods.reset({
      first_name: accountData?.account.first_name,
      last_name: accountData?.account.last_name,
      email: accountData?.account.email,
      phone: accountData?.account.phone || '',
      date_of_birth: accountData?.account.date_of_birth,
    });
  };

  const onFormSubmit = async (data: ProfileFormState) => {
    if (isAccountUpdatePending || !isDataChanged) {
      return;
    }
    try {
      await updateAccount(data);
      setShowNotification('Your Profile has been updated');
      track('Account Profile Updated', { status: AnalyticEventStatus.Success });
    }
    catch (error) {
      reportAPIExecutionError('Failed to update account data', error);
      setShowNotification('Something went wrong. Please try again later.');
      track('Account Profile Updated', { status: AnalyticEventStatus.Error });
    }
  };

  return (
    <div css={customCSS}>
      <Notification
        open={!!updateNotification}
        message={updateNotification || ''}
        onClose={() => setShowNotification(null)}
      />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onFormSubmit, scrollToError)}>
          <div css={formContainerCSS}>
            {PROFILE_FORM_SCHEMA.map((questionProps) => {
              return <Question key={questionProps.questionKey} {...questionProps} />;
            })}
          </div>
          <div css={actionButtonsCSS}>
            <Button variant="secondary" disabled={!isDataChanged || isAccountUpdatePending} onClick={onFormReset}>
              Discard Changes
            </Button>
            <Button type="submit" isLoading={isAccountUpdatePending}>
              Update Profile
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default ProfileForm;
