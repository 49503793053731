import type { FC } from 'react';
import { Link } from 'react-router-dom';
import ManLookingInPhoneIcon from '../../../assets/svg/man-looking-in-phone.svg?react';
import useSettingsQuery from '../../../api/settings/use-settings-query';
import { linkCSS } from '../../../styles/common.style';
import { containerCSS, pageHeadingCSS } from './ComingSoon.style';
import type { ComingSoonProps } from './ComingSoon.type';

const ComingSoon: FC<ComingSoonProps> = ({ customCSS }) => {
  const { data: settingsData } = useSettingsQuery();

  return (
    <div css={[containerCSS, customCSS]}>
      <h1 css={pageHeadingCSS}>
        Your Policies—Coming Soon!
      </h1>

      <ManLookingInPhoneIcon />

      <p>
        We’re building a smarter way to keep all your policies in one place. Soon, you’ll be able to view
        and manage every detail, in one organized hub. We’re excited to bring this to you—stay tuned!
        If you have questions about your policy, call us
        at <Link to={`tel:${settingsData?.phone_number}`} css={linkCSS} role="link">{settingsData?.phone_number}</Link>.
      </p>
    </div>
  );
};

export default ComingSoon;
