import type { FC } from 'react';

import { Navigate, useMatch, useParams } from 'react-router-dom';

import useAccountQuery from '../../api/account/use-account-query.ts';
import ROUTES from '../../constants/routes.ts';
import FullPageLoader from '../../shared/components/FullPageLoader/FullPageLoader.tsx';
import isGid from '../../utils/is-gid.util.ts';
import isDemo from '../../utils/is-demo.util.ts';
import type { AuthGuardProps } from './AuthGuard.type';

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { gid } = useParams();
  const verifyPathData = useMatch(ROUTES.verify);
  const accountGidParam = isGid(gid) ? gid : null;
  const { data: accountData } = useAccountQuery(accountGidParam);

  if (isDemo()) {
    return <>{children}</>;
  }

  if (!accountGidParam) {
    return <Navigate to={ROUTES.problem} />;
  }

  if (!accountData) {
    return <FullPageLoader />;
  }

  if (!verifyPathData && accountData?.account && !accountData.account.verified) {
    return <Navigate to={ROUTES.verify.replace(':gid', gid as string)} />;
  }

  return <>{children}</>;
};

export default AuthGuard;
