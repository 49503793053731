import type { FC } from 'react';
import { useContext, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { ActionCableContext } from '../../../context/action-cable-context.ts';
import type { Subscription } from '../../../services/ActionCable';
import useProposalUserPresenceQuery from '../../../api/proposal/use-proposal-user-presence-query';
import QUERY_CACHE_KEYS from '../../../constants/query-cache-keys';
import type { ProposalPagePresence } from '../../../types/proposal-page-presence.type';
import PresenceBadge from '../../../assets/svg/presence-badge.svg?react';
import Moon from '../../../assets/svg/moon.svg?react';
import Hourglass from '../../../assets/svg/hourglass.svg?react';
import type { ProposalsPresenceIndicatorProps } from './ProposalPresenceIndicator.type';
import { containerCSS, presenceCircleCSS } from './ProposalPresenceIndicator.style';

const ProposalPresenceIndicator: FC<ProposalsPresenceIndicatorProps> = ({ proposal, refetchProposal }) => {
  const proposalGid = proposal.gid;
  const engagementGid = proposal.engagement_gid;

  const isProposalSent = !!proposal.sent_at;
  const isProposalOpened = !!proposal.opened_at;

  const cable = useContext(ActionCableContext);

  const queryClient = useQueryClient();
  const { data: userPresence, refetch: refetchUserPresence } = useProposalUserPresenceQuery({ proposalGid });

  useEffect(() => {
    let engagementProposalsSubscription: Subscription;

    if (cable && !isProposalSent) {
      engagementProposalsSubscription = cable.subscriptions.create(
        { channel: 'EngagementProposalsChannel', engagement_gid: engagementGid },
        { received: async () => {
          await refetchProposal();
        } },
      );
    }

    return () => {
      if (cable && !isProposalSent) {
        engagementProposalsSubscription.unsubscribe();
      }
    };
  }, [cable, isProposalSent, engagementGid, refetchProposal]);

  useEffect(() => {
    let proposalPresenceSubscription: Subscription;
    let refetchInterval: NodeJS.Timeout;

    if (cable && isProposalSent) {
      proposalPresenceSubscription = cable.subscriptions.create(
        { channel: 'ProposalPresenceChannel', proposal_gid: proposalGid },
        {
          received: (proposalPagePresence: ProposalPagePresence) => {
            queryClient.setQueryData([QUERY_CACHE_KEYS.proposalUserPresence, proposalGid], proposalPagePresence);

            // Clear the interval that was set after previous heartbeat notification was received.
            if (refetchInterval) {
              clearInterval(refetchInterval);
            };

            /* Set an interval to refetch the user presence
              if there is no heartbeat notification received for 5 seconds. */
            refetchInterval = setInterval(async () => {
              await refetchProposal();
              await refetchUserPresence();

              clearInterval(refetchInterval);
            }, 5000);
          },
        },
      );
    }

    return () => {
      if (cable && isProposalSent) {
        proposalPresenceSubscription.unsubscribe();
      }
    };
  }, [cable, isProposalSent, proposalGid, queryClient, refetchProposal, refetchUserPresence]);

  if (!isProposalSent) {
    return null;
  }

  if (userPresence?.user_presence) {
    return (
      <div css={containerCSS(true)}>
        <PresenceBadge css={presenceCircleCSS} />
        <div>
          Your customer is online on the page
        </div>
      </div>
    );
  }

  if (isProposalOpened) {
    return (
      <div css={containerCSS(false)}>
        <Moon />
        <div>
          Your customer left the page
        </div>
      </div>
    );
  }

  return (
    <div css={containerCSS(false)}>
      <Hourglass />
      <div>
        Wait for your customer
      </div>
    </div>
  );
};

export default ProposalPresenceIndicator;
