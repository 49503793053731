import { css } from '@emotion/react';
import THEME from '../../../styles/theme.ts';
import { mq } from '../../../styles/media-queries.ts';

export const ANIMATE_HIDE_CLASS = 'animate-hide';

export const animationContainerCSS = css`
  position: relative;
  top: 0;
  transition: top 0.5s ease-out 0.3s, max-height 0.5s ease-out;
  max-height: 1000px;
  &.${ANIMATE_HIDE_CLASS} {
    top: -600px;
    max-height: 0;
    overflow: hidden;
  }
`;

export const containerCSS = css`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${THEME.color.azure95};
  border-radius: ${THEME.size.borderRadiusLarge}px;
  padding: 20px 16px;
  gap: 20px;
  margin: 0 -${THEME.size.paddingBodyHorizontalMobile}px 32px;
  ${mq[1]} {
    padding: 32px;
    gap: 40px;
    margin: 0 0 60px;
    flex-direction: row;
    align-items: center;
  }
`;

export const closeButtonCSS = css`
  position: absolute;
  top: 20px;
  right: 16px;
  ${mq[1]} {
    top: 24px;
    right: 24px;
  }
`;

export const imageCSS = css`
  flex-shrink: 0;
`;

export const textColCSS = css`
  h3 {
    font-size: 20px;
    line-height: 1.4;
    font-weight: 700;
    margin: 0 0 4px;
  }
  ${mq[1]} {
    h3 {
      font-size: 32px;
      line-height: 1.25;
      margin-bottom: 12px;
    }
  }
`;
