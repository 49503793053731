import { css } from '@emotion/react';
import { mq } from '../../../styles/media-queries';

export const containerCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;

  ${mq[1]} {
    gap: 40px;
  }

  svg {
    width: 100%;
  }
`;

export const pageHeadingCSS = css`
  font-size: 32px;
  line-height: 40px;
  margin: 0;

  ${mq[1]} {
    font-size: 40px;
    line-height: 52px;
  }
`;
