import currencyFormatter from 'currency-formatter';
import { format as formatDate } from 'date-fns';

import type { Address } from '../types/address.type';

type MoneyFormatter = (
  value: string | number,
  options?: {
    cutFromK?: number;
    prefix?: string | null;
    suffix?: string | null;
  }
) => string;

const money: MoneyFormatter = (value, options = {}) => {
  const { cutFromK = 0, prefix = '', suffix = '' } = options;

  if (typeof value === 'number' || !Number.isNaN(Number.parseInt(value))) {
    const thousands = +value / 1000;

    if (cutFromK && thousands >= cutFromK) {
      const precision = thousands % 1 > 0.099 ? 1 : 0;
      return `${prefix}${currencyFormatter.format(thousands, { precision })}k${suffix}`;
    }

    return `${prefix}${currencyFormatter.format(Math.round(+value), { precision: 0 })}${suffix}`;
  }

  return '';
};

type SnakeFormatter = (string: string, separator?: string) => string;

const snake: SnakeFormatter = (snakeCase, separator = ' ') =>
  snakeCase
    .toLowerCase()
    .split('_')
    .map(word => word[0].toUpperCase() + word.slice(1))
    .join(separator);

function phone(phoneNumberString: string): string {
  const cleaned = phoneNumberString.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return phoneNumberString;
}

function maskNumbers(numbersString: string, limit: number): string {
  if (!numbersString) {
    return '';
  }
  let maskedNumber = 0;
  return Array.from(numbersString)
    .map((letter) => {
      if (letter.match(/\d/) && maskedNumber < limit) {
        maskedNumber++;
        return '*';
      }
      return letter;
    })
    .join('');
}

function maskEmail(email?: string): string {
  if (!email) {
    return '';
  }
  const [name, domain] = email.split('@');
  return `${name.slice(0, 2)}****${name.slice(name.length - 2)}@${domain}`;
}

function date(value: string): string {
  if (!value) {
    return '';
  }
  return formatDate(new Date(value), 'L/d/y');
}

function address(value: Address): string {
  return value?.line1 ? `${value.line1}, ${value.city}, ${value.state} ${value.zip}` : '';
}

const format = {
  money,
  snake,
  phone,
  maskNumbers,
  maskEmail,
  date,
  address,
};

export default format;
