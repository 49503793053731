import type { SignUpPayload } from './account.type';

export interface HTTPResponse<T = unknown> {
  data: T;
  status: 'success' | 'fail';
  session: {
    refreshed_at: number; // unix time in seconds
  };
  message?: string;
}

export enum HTTPMethod {
  get = 'GET',
  post = 'POST',
  put = 'PUT',
  patch = 'PATCH',
  head = 'HEAD',
  delete = 'DELETE',
}

export interface ApiCallParams {
  headers?: any;
  body?: any;
  params?: any;
}

export type ApiCall = <T = unknown>(url: string, params?: ApiCallParams) => Promise<T>;

export interface Api {
  get: ApiCall;
  post: ApiCall;
  put: ApiCall;
  patch: ApiCall;
  head: ApiCall;
  delete: ApiCall;
}

export interface ErrorResponseData {
  key: keyof SignUpPayload;
  message: string;
}

export interface SignUpError extends Error {
  response?: {
    status: number;
    data: {
      data: ErrorResponseData;
    };
  };
}
