import { css } from '@emotion/react';
import THEME from '../../styles/theme';

export const demoFlowIndicatorCSS = css`
  width: 100%;
  text-align: center;
  background: linear-gradient(123.38deg, ${THEME.color.azure62} 11.91%, ${THEME.color.azure80} 100.01%);
  font-size: 14px;
  line-height: 20px;
  box-sizing: border-box;
  padding: 3px 0;
  color: white;
  font-weight: 700;
  z-index: 99999;
`;
