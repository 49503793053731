import type { FC } from 'react';
import { useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import useSendMagicLink from '../../../api/authentification/use-send-magic-link.ts';
import EmailQuestion from '../../../components/Question/EmailQuestion/EmailQuestion.tsx';
import ROUTES from '../../../constants/routes.ts';
import Button from '../../../shared/components/Button/Button.tsx';
import { REQUIRED_VALIDATION } from '../../../utils/question-validations.util.ts';
import useSegment from '../../../hooks/use-segment.ts';
import { ANALYTICS_SESSION_GID } from '../../../constants/browser-storage.ts';
import { noopAsync } from '../../../shared/utils/noop.ts';
import { AnalyticPageKey } from '../../../types/analytics.type.ts';
import { reportAPIExecutionError } from '../../../utils/error.util.ts';
import Notification from '../../../shared/components/Notification/Notification.tsx';
import useTimer from '../../../hooks/use-timer.ts';
import type { SignInFormProps } from './SignInForm.type.ts';

import { containerCSS } from './SignInForm.style.ts';

const SignInForm: FC<SignInFormProps> = ({ customCSS, onBeforeSubmit = noopAsync }) => {
  const methods = useForm<{ email: string }>();
  const {
    mutateAsync: sendMagicLink,
    isPending: isSendMagicLinkPending,
  } = useSendMagicLink();
  const navigate = useNavigate();

  const [failedSendMagicLink, setFailedSendMagicLink] = useState(false);
  const { time, startTimer } = useTimer();

  const { track } = useSegment();

  const onSubmit = async (data: { email: string }): Promise<void> => {
    await onBeforeSubmit();
    const sessionGid = localStorage.getItem(ANALYTICS_SESSION_GID) || '';

    track('Authenticate Link Sent', {
      page: AnalyticPageKey.SignIn,
      session_gid: sessionGid,
    });

    const res = await sendMagicLink({
      email: data.email,
      session_gid: sessionGid,
    });
    navigate(`${ROUTES.checkEmail}?email=${res.account.email}`, { state: { email: data.email } });
  };

  const handleSubmit = async (data: { email: string }): Promise<void> => {
    try {
      await onSubmit(data);
    }
    catch (error) {
      startTimer(3);
      setFailedSendMagicLink(true);
      reportAPIExecutionError('Failed to send magic link', error);
    }
  };

  return (
    <div css={[containerCSS, customCSS]}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <EmailQuestion questionKey="email" label="Email" validations={[REQUIRED_VALIDATION]} trackingForbidden />
          <Button type="submit" fullWidth size="large" disabled={time > 0} isLoading={isSendMagicLinkPending}>
            Send Link
          </Button>
        </form>
      </FormProvider>

      {/** TODO: Uncomment after SignUp release */}
      {/* <p css={signUpWrapperCSS}> */}
      {/*  Don’t have an account? {' '} */}
      {/*  <Button variant="text" onClick={() => navigate(ROUTES.signUp)}> */}
      {/*    Sign up here. */}
      {/*  </Button> */}
      {/* </p> */}

      <Notification
        open={failedSendMagicLink}
        message="Something went wrong. Please try again later."
        autoHideDuration={10000}
        onClose={() => setFailedSendMagicLink(false)}
      />
    </div>
  );
};

export default SignInForm;
