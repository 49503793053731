import { useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api.ts';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys.ts';
import useSessionTimeoutStore from '../../context/session-timeout-context.ts';
import type { HTTPResponse } from '../../types/api.type.ts';
import api from '../api.ts';
import type { DigitalProfileResponse } from '../../types/digital-profile.type.ts';
import type { AnalyticPageKey } from '../../types/analytics.type.ts';
import { checkResponseType } from '../../utils/error.util.ts';
import { DigitalProfileResponseSchema } from './digital-profile.schema.ts';

function useDigitalProfileQuery(gid: string, targetPage: AnalyticPageKey) {
  const updateLastUserInteractionTime = useSessionTimeoutStore(s => s.updateLastUserInteractionTime);

  return useQuery({
    queryKey: [QUERY_CACHE_KEYS.digitalProfile, gid],
    throwOnError: false,
    queryFn: async () =>
      await api
        .get<HTTPResponse<DigitalProfileResponse>>(
          API_PATH.digitalProfile().replace(':gid', gid),
          { params: { target_page: targetPage } },
        )
        .then((res) => {
          if (res.session.refreshed_at) {
            updateLastUserInteractionTime(res.session.refreshed_at * 1000);
          }

          return checkResponseType<DigitalProfileResponse>(res.data, DigitalProfileResponseSchema);
        }),
  });
}

export default useDigitalProfileQuery;
