import type { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import homeIcon from '../../../assets/svg/home.svg';
import shieldIcon from '../../../assets/svg/checkbox-shield.svg';
import carIcon from '../../../assets/svg/car.svg';
import PolicyCard from '../PolicyCard/PolicyCard';
import type { DefaultRouteParams } from '../../../types/route-params.type';
import useSettingsQuery from '../../../api/settings/use-settings-query';
import useAccountQuery from '../../../api/account/use-account-query';
import useSegment from '../../../hooks/use-segment';
import { AnalyticAddNewPolicyVariant } from '../../../types/analytics.type';
import ROUTES from '../../../constants/routes';
import { pageHeadingCSS } from '../../../styles/layout.style';
import { containerCSS } from './MyPolicies.style';
import type { MyPoliciesProps } from './MyPolicies.type';

const MyPolicies: FC<MyPoliciesProps> = ({ customCSS }) => {
  const { gid } = useParams() as DefaultRouteParams;
  const { data: settingsData } = useSettingsQuery();
  const { data: accountData } = useAccountQuery(gid);

  const { track } = useSegment();

  const navigate = useNavigate();

  const onActionClick = async (variant: AnalyticAddNewPolicyVariant) => {
    track('Add New Policy Clicked', {
      policy_variant: variant,
    });

    switch (variant) {
      case 'home':
        if (accountData?.account.profile_completed_at) {
          navigate(ROUTES.carrierOptions.replace(':gid', gid));
        }
        else {
          navigate(ROUTES.scheduleCall.replace(':gid', gid));
        }
        break;
      case 'auto':
        if (settingsData?.matic_auto_flow_url) {
          window.open(settingsData?.matic_auto_flow_url, '_blank');
        }
        break;
      case 'other':
        navigate(ROUTES.scheduleCall.replace(':gid', gid));
        break;
    }
  };

  return (
    <div css={[customCSS]}>
      <h1 css={pageHeadingCSS}>
        My Policies
      </h1>

      <div css={containerCSS}>
        <PolicyCard
          icon={{ src: homeIcon }}
          title="Add a home policy"
          onActionClick={() => onActionClick(AnalyticAddNewPolicyVariant.Home)}
        />
        <PolicyCard
          icon={{ src: carIcon }}
          title="Add an auto policy"
          onActionClick={() => onActionClick(AnalyticAddNewPolicyVariant.Auto)}
        />
        <PolicyCard
          icon={{ src: shieldIcon }}
          title="Add another policy"
          onActionClick={() => onActionClick(AnalyticAddNewPolicyVariant.Other)}
          buttonText="Schedule a Call"
        />
      </div>
    </div>
  );
};

export default MyPolicies;
