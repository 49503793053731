export const RATE_REVIEW = '/rate_reviews/:gid';
export const RATE_REVIEW_CONFIRMATION = '/rate_reviews/:gid/confirmation';
export const ERROR = '/error';

const ROUTES = {
  authenticate: '/authenticate',

  auth: '/auth',
  signIn: '/auth/sign-in',
  signUp: '/auth/sign-up',
  checkEmail: '/auth/check-email',
  linkExpired: '/auth/link-expired',

  sessionBase: '/:gid',
  account: '/:gid/account',
  verify: '/:gid/verify',
  insights: '/:gid/insights',
  policies: '/:gid/policies',
  scheduleCall: '/:gid/schedule-call',
  rateReview: '/:gid/rate-review',
  replacementCost: '/:gid/replacement-cost',
  snapshotDetails: '/:gid/snapshot-details',
  digitalProfileIntro: '/:gid/digital-profile-intro',
  carrierOptions: '/:gid/carrier-options',
  creditScoreDetails: '/:gid/credit-score-details',
  disclaimers: '/:gid/disclaimers',

  proposal: '/proposal/:proposal_gid',

  problem: '/problem',
  invalid: '/*',
};

export default ROUTES;
