import type { FC } from 'react';
import Container from '../../../kit_deprecated/Container/Container';
import getManualQuotes from '../../../utils/manualQuotes';
import Faq from '../../Faq/Faq';
import ManualQuoteCard from '../../ManualQuoteCard/ManualQuoteCard';
import ScheduleCall from '../../ScheduleCall/ScheduleCall';
import type { RateReviewVariation } from '../Variation';
import { ContainerSize } from '../../../kit_deprecated/Container/Container.type';

const NoQuotes: FC<RateReviewVariation> = ({ rateReviewData, callbacks }) => {
  return (
    <Container className="c-rate-review" size={ContainerSize.Small}>
      <div className="c-rate-review__container">
        <div className="c-rate-review__header">
          <h1 className="u-mb-2">Matic Needs More Information</h1>
          <Container size={ContainerSize.Small}>
            <div className="c-rate-review__header--details">
              We’re missing facts about your home which is preventing us from getting quotes. Call our insurance
              advisors now to confirm a few property details and see how much you can save.
            </div>
          </Container>
        </div>

        <ScheduleCall onScheduleCall={callbacks.onScheduleCall} />

        <h2 className="c-rate-review__quotes-header u-hidden--mobile">Your Homeowner Insurance Options</h2>

        <h4 className="c-rate-review__quotes-header--mobile u-hidden--desktop">Your Insurance Options</h4>

        {getManualQuotes(rateReviewData.home.usage).map((quote, index) => (
          <ManualQuoteCard
            key={quote.carrier.key}
            quote={quote}
            onManualQuoteSelect={callbacks.onManualQuoteSelect}
            onQuoteReview={callbacks.onQuoteReview}
            ordinal={index}
          />
        ))}

        <div className="c-rate-review__faq-wrapper">
          <Faq onFAQClicked={callbacks.onFAQClicked} />
        </div>
      </div>
    </Container>
  );
};

export default NoQuotes;
