import debounce from 'debounce-promise';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { FC } from 'react';

import { streetService } from '../../../api/street';
import type { AddressSuggestion, SuggestionResponse } from '../../../types/smarty-streets.type';
import noop from '../../../shared/utils/noop';
import ValidationError from '../../../shared/components/ValidationError/ValidationError';
import Input from '../../../shared/components/Input/Input';
import { FS_SENSITIVE_DATA_CLASS } from '../../../constants/full-story';
import { reportErrorMessage } from '../../../utils/error.util';
import { addressCSS, addressMenuCSS, errorCSS, loadingCSS, menuListCSS } from './Address.style';
import { getDefaultValueString, suggestionToAnswer } from './Address.util';
import AddressOption, { getOptionString } from './AddressOption';
import type { AddressProps, SuggestionsState } from './Address.type';

const Address: FC<AddressProps> = ({
  name,
  placeholder,
  isDisabled,
  isPrefilled,
  inputId,
  value,
  errorHeading,
  trackingForbidden = false,
  dense = false,
  componentRef,
  onBlur: controlledBlur,
  onValidEntry = noop,
}) => {
  const addressEl = useRef<HTMLInputElement>(null);
  const inputEl = useRef<HTMLInputElement>(null);

  const [isFocused, setFocused] = useState(false);
  const [selected, setSelected] = useState<string | null>(null);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [isInvalid, setInvalid] = useState(false);
  const [options, setOptions] = useState<SuggestionsState>({ data: [], isLoading: false });

  useEffect(() => {
    if (componentRef && inputEl.current) {
      componentRef(inputEl.current);
    }
  }, [componentRef, inputEl]);

  const dataRef = useRef({ opts: options.data, defaultValue: '', focusedIndex });
  dataRef.current.opts = options.data;
  dataRef.current.defaultValue = useMemo(() => getDefaultValueString(value), [value]);
  dataRef.current.focusedIndex = focusedIndex;

  const isOpen = isFocused && (!!options.data.length || options.isLoading);
  const errorMsg = !options.data.length && 'We couldn’t find this address.';

  const onInput = useMemo(
    () =>
      debounce((inputValue: string) => {
        if (inputValue) {
          if (document.activeElement === inputEl.current) {
            setFocused(true);
          }
          setOptions(options => ({ ...options, isLoading: true }));

          streetService
            .suggest(inputValue)
            .then((response: SuggestionResponse) => {
              setFocusedIndex(-1);
              setOptions({ data: response.data.suggestions || [], isLoading: false });
            })
            .catch((error) => {
              reportErrorMessage(error);
              setFocusedIndex(-1);
              setOptions({ data: [], isLoading: false });
            });
        }
      }, 1000),
    [],
  );

  const onSelect = useCallback(
    (option: AddressSuggestion, optionString: string) => {
      if (+option.entries > 1) {
        setOptions(options => ({ ...options, isLoading: true }));
        streetService
          .suggestSecondary(inputEl.current?.value as string, optionString)
          .then((response: SuggestionResponse) =>
            setOptions({ data: response.data.suggestions || [], isLoading: false }),
          )
          .catch((error) => {
            reportErrorMessage(error);
            setOptions({ data: [], isLoading: false });
          });

        if (inputEl.current) {
          inputEl.current.value = option.street_line;
          inputEl.current.focus();
        }
      }
      else {
        if (inputEl.current)
          inputEl.current.value = optionString;
        onValidEntry(suggestionToAnswer(option));
        setFocused(false);
        setInvalid(false);
        setSelected(getDefaultValueString(suggestionToAnswer(option)));
      }
    },
    [onValidEntry],
  );
  const keyBinding = useCallback(
    (e: KeyboardEvent) => {
      switch (e.code) {
        case 'ArrowDown':
          setFocusedIndex(i => (dataRef.current.opts.length === ++i ? -1 : i));
          break;
        case 'ArrowUp':
          setFocusedIndex(i => (i === -1 ? dataRef.current.opts.length - 1 : --i));
          break;
        case 'Enter': {
          const option = dataRef.current.opts[dataRef.current.focusedIndex];
          if (option) {
            onSelect(option, getOptionString(option));
          }
          break;
        }
        case 'Escape': {
          inputEl.current?.blur();
          setFocused(false);
          break;
        }
        default:
      }
    },
    [onSelect],
  );

  const onBlur = useCallback(
    (e: any) => {
      if (!addressEl.current?.contains(e.target)) {
        const inputValue = inputEl.current?.value.trim();
        document.removeEventListener('keydown', keyBinding);
        setFocused(false);
        setInvalid(!inputValue || inputValue !== dataRef.current.defaultValue);
        setFocusedIndex(-1);
      }
    },
    [keyBinding],
  );
  const onFocus = (): void => {
    setFocused(true);
    document.addEventListener('click', onBlur);
    document.addEventListener('keydown', keyBinding);
  };

  const onInputBlur = (): void => {
    controlledBlur?.();
  };

  useEffect(() => {
    if (!isFocused) {
      document.removeEventListener('click', onBlur);
      document.removeEventListener('keydown', keyBinding);
    }
  }, [isFocused, onBlur, keyBinding]);

  return (
    <div css={addressCSS} ref={addressEl} data-testid="address">
      <Input
        className={trackingForbidden ? FS_SENSITIVE_DATA_CLASS.mask : ''}
        autoComplete="off"
        hasError={!isOpen && isInvalid}
        name={name}
        id={inputId}
        onInput={(e) => {
          onValidEntry((e.target as HTMLInputElement).value);
          onInput((e.target as HTMLInputElement).value);
        }}
        type="search"
        onFocus={onFocus}
        placeholder={placeholder}
        ref={inputEl}
        defaultValue={dataRef.current.defaultValue}
        data-testid="address-input"
        disabled={isDisabled}
        isPrefilled={isPrefilled}
        dense={dense}
        onBlur={onInputBlur}
      />
      <div css={addressMenuCSS(isOpen)} data-testid="address-menu">
        <div css={menuListCSS} role="listbox" aria-label="Address menu list">
          {options.data.map((option, i) => (
            <AddressOption
              key={JSON.stringify(option)}
              inputValue={inputEl.current?.value}
              option={option}
              index={i}
              isFocused={focusedIndex === i}
              trackForbidden={trackingForbidden}
              onSelect={onSelect}
              onHover={i => setFocusedIndex(i)}
              isSelected={selected === getDefaultValueString(suggestionToAnswer(option))}
            />
          ))}
          {options.isLoading && <div css={loadingCSS}>Loading...</div>}
        </div>
      </div>
      {errorMsg && (
        <div css={errorCSS(isInvalid)} data-testid="address-error">
          <ValidationError heading={errorHeading} visible={isInvalid}>
            {errorMsg}
          </ValidationError>
        </div>
      )}
    </div>
  );
};

export default Address;
