import { useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import useSessionTimeoutStore from '../../context/session-timeout-context.ts';
import type { HTTPResponse } from '../../types/api.type';
import type { ScoreResponse, ScoreType } from '../../types/score.type.ts';
import api from '../api';
import { checkResponseType } from '../../utils/error.util.ts';
import { ScoreResponseSchema } from './score.schema.ts';

function useScoreQuery(gid: string, type: ScoreType) {
  const updateLastUserInteractionTime = useSessionTimeoutStore(s => s.updateLastUserInteractionTime);

  return useQuery({
    queryKey: [QUERY_CACHE_KEYS.score, gid, type],
    queryFn: async () =>
      await api
        .get<HTTPResponse<ScoreResponse>>(API_PATH.scores.replace(':gid', gid).replace(':type', type))
        .then((res) => {
          if (res.session.refreshed_at) {
            updateLastUserInteractionTime(res.session.refreshed_at * 1000);
          }

          return checkResponseType<ScoreResponse>(res.data, ScoreResponseSchema);
        }),
  });
}

export default useScoreQuery;
