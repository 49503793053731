/**
 * MUI Tooltip styles
 */
import { type SerializedStyles, css } from '@emotion/react';

import THEME from './theme.ts';

export enum TooltipTheme {
  Dark = 'dark',
  Light = 'light',
}

const lightTooltip = {
  backgroundColor: THEME.color.white,
  maxWidth: '360px',
  padding: '0',
  borderRadius: '4px',
  border: `1px solid ${THEME.color.gray30}`,
  boxShadow: '2px 4px 0px 0px rgba(0, 0, 0, 0.12)',
  margin: '12px !important',
};

const darkTooltip = {
  backgroundColor: `${THEME.color.gray60}`,
  borderRadius: '2px',
  padding: '0',
  margin: '12px !important',
};

const lightTooltipArrow = {
  'color': `${THEME.color.white}`,

  '&:before': {
    border: `1px solid ${THEME.color.gray30}`,
  },
};

const darkTooltipArrow = { color: THEME.color.gray60 };

export function muiTooltipCSS(tooltipTheme?: TooltipTheme): Record<string, string> {
  return tooltipTheme === TooltipTheme.Light ? lightTooltip : darkTooltip;
}

export function tooltipArrowCSS(tooltipTheme?: TooltipTheme): Record<string, string> {
  return tooltipTheme === TooltipTheme.Light ? lightTooltipArrow : darkTooltipArrow;
}

export function tooltipContentCSS(tooltipTheme: TooltipTheme = TooltipTheme.Light): SerializedStyles {
  return css`
  color: ${tooltipTheme === TooltipTheme.Light ? THEME.color.gray80 : THEME.color.white};
  padding: ${tooltipTheme === TooltipTheme.Light ? '20px' : '8px'};
  font-size: 14px;
  line-height: 20px;
  
  a {
    color: ${THEME.color.azure68};
  }

  p:not(:last-of-type) {
    margin-bottom: 8px;
  }
`;
}

export const linkCSS = css`
  a {
    transition: color 0.3s;

    color: ${THEME.color.azure62};
    text-decoration: none;

    ::active {
      color: ${THEME.color.azure62};
    }

    :hover {
      color: ${THEME.color.azure68};
    }
  }
`;
