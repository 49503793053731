import type { FC, ReactElement } from 'react';

import { get, useFormContext } from 'react-hook-form';

import ValidationError from '../../shared/components/ValidationError/ValidationError.tsx';
import { useDynamicTextReplacements } from '../../hooks/use-dynamic-text-replacements.tsx';
import ReplacedText from '../../shared/components/ReplacedText/ReplacedText.tsx';
import QuestionLabel from './QuestionLabel/QuestionLabel';
import {
  containerCSS,
  descriptionWithIconCSS,
  errorCSS,
  iconLabelWrapperCSS,
  innerWrapperCSS,
  labelCSS,
  labelWithIconCSS,
} from './QuestionWrapper.style';
import type { QuestionWrapperProps } from './QuestionWrapper.type';

export const QuestionWrapper: FC<QuestionWrapperProps> = ({
  customCSS,
  hint,
  description,
  labelTag,
  hideLabel = false,
  label,
  icon,
  questionKey,
  inputComponent: InputComponent,
  inputProps,
  isDisabled,
  gridWidth,
}) => {
  const { formState: { errors } } = useFormContext();
  const dynamicTextReplacements = useDynamicTextReplacements();

  const error = get(errors, questionKey) || '';

  const renderInnerWithIcon = (): ReactElement => (
    <div css={innerWrapperCSS}>
      <div css={iconLabelWrapperCSS}>
        <img src={icon} height={48} alt="" />
        {!hideLabel && label && (
          <QuestionLabel customCSS={labelWithIconCSS} wrapperElement={labelTag} keyName={questionKey}>
            {label}
          </QuestionLabel>
        )}
      </div>

      {description && <div css={descriptionWithIconCSS}>{description}</div>}

      <InputComponent {...inputProps} dense hasError={!!error?.message} />
      <ValidationError customCSS={errorCSS} heading={label} visible={!!error?.message}>
        <ReplacedText replacements={dynamicTextReplacements} template={error?.message} />
      </ValidationError>
    </div>
  );

  const renderDefaultInner = (): ReactElement => (
    <>
      {!hideLabel && label && (
        <QuestionLabel customCSS={labelCSS} wrapperElement={labelTag} keyName={questionKey} tooltipText={hint}>
          {label}
        </QuestionLabel>
      )}
      <InputComponent {...inputProps} hasError={!!error?.message} />
      <ValidationError customCSS={errorCSS} heading={label} visible={!!error?.message}>
        <ReplacedText replacements={dynamicTextReplacements} template={error?.message} />
      </ValidationError>
    </>
  );

  return (
    <div css={[containerCSS(isDisabled, gridWidth), customCSS]} data-testid={`question-input-${questionKey}`}>
      {icon ? renderInnerWithIcon() : renderDefaultInner()}
    </div>
  );
};

export default QuestionWrapper;
