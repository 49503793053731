const THEME = {
  typography: {
    fontFamily: 'freight-sans-pro, sans-serif',
    fontSize: 16,
    lineHeight: 1.25,
  },
  color: {
    azure50: '#156EEA',
    azure62: '#408DF7',
    azure68: '#66A4F7',
    azure80: '#A3C7FA',
    azure95: '#E5F1FF',
    black: '#000000',
    cherise61: '#EA4D72',
    errorRed: '#C80000',
    errorRed96: '#FFECEC',
    gray5: '#F2F2F2',
    gray10: '#E6E6E6',
    gray30: '#B3B3B3',
    gray60: '#666666',
    gray80: '#333333',
    peachPink: '#ffa7a7',
    positiveGreen94: '#E8F8E8',
    positiveGreen27: '#2F8802',
    aqua95: '#EAFBF9',
    aqua: '#6EE4D6',
    violet95: '#F9F6FF',
    violet87: '#C9BBFF',
    violet67: '#8D55FF',
    violet: '#5920CE',
    primarySun: '#F9E14B',
    carrot: '#fc951d',
    orangePrimary: '#fa7032',
    warningYellow94: '#FFF1E0',
    white: '#FFFFFF',
  },
  size: {
    containerMaxWidth: 900,
    containerSmMaxWidth: 600,
    paddingBodyHorizontalMobile: 16,
    borderRadius: 4,
    borderRadiusInner: 8,
    borderRadiusLarge: 12,
    headerHeight: 48,
    inputPaddingHorizontal: 20,
    inputPaddingHorizontalSmall: 10,
    controlHeight: 60,
    controlHeightSmall: 44,
    inputHorizontalSpacing: 16,
  },
  boxShadow: {
    container: '2px 4px 0px 0px #0000001F',
    containerHovered: '4px 8px 0px 0px #0000001F',
  },
};

export default THEME;
