import { z } from 'zod';
import { CarrierSchema } from '../available-carriers/available-carriers.schema';
import { PaymentMethod } from '../../types/person-loan.type';
import { PolicyType } from '../../types/quote.type';
import { QuoteCoverageSchema } from '../quotes/quote.schema';

export const PolicySchema = z.object({
  asset_gids: z.array(z.string()),
  carrier: CarrierSchema,
  created_at: z.string(),
  // TODO change back to datetime
  effective_date: z.string(),
  expiration_date: z.string(),
  gid: z.string(),
  lender_placed_insurance_issue_date: z.string().datetime({ offset: true }).nullable(),
  agency_name: z.string().nullable(),
  payment_method: z.nativeEnum(PaymentMethod).nullable(),
  policy_number: z.string(),
  policy_type: z.nativeEnum(PolicyType),
  premium: z.string(),
  status: z.string(),
  coverages: z.array(QuoteCoverageSchema),
});

export const RecentPolicyResponseSchema = z.object({
  person_policy: PolicySchema,
});
