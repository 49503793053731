import { z } from 'zod';

export const DisclosureSchema = z.object({
  gid: z.string(),
  content: z.string(),
  kind: z.string(),
  prompt: z.string(),
});

export const DisclosureResponseSchema = z.object({
  disclosures: z.array(DisclosureSchema),
});
