import { z } from 'zod';

export const CarrierSchema = z.object({
  key: z.string().nullish(),
  name: z.string().nullish(),
  logo_url: z.string().url().nullish(),
});

export const AvailableCarrierSchema = CarrierSchema.extend({
  supported: z.boolean().optional(),
});

export const AvailableCarriersResponseSchema = z.object({
  carriers: z.array(AvailableCarrierSchema),
});
