import { css } from '@emotion/react';
import type { SerializedStyles } from '@emotion/react';
import THEME from '../../../styles/theme';

export function addressMenuCSS(isOpen: boolean): SerializedStyles {
  return css`
  background-color: ${THEME.color.white};
  border: 1px solid ${THEME.color.gray30};
  border-radius: ${THEME.size.borderRadius}px;
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.09);
  box-sizing: border-box;
  display: none;
  position: absolute;
  top: ${THEME.size.controlHeight + 4}px;
  width: 100%;
  z-index: 2;
  padding: 4px 0;

  ${isOpen
  && css`
    display: block;
  `};
`;
}

export const menuListCSS = css`
  max-height: 400px;
  overflow: auto;
`;

export const addressCSS = css`
  position: relative;
`;

export const loadingCSS = css`
  color: ${THEME.color.gray60};
  padding: 8px 20px;
  text-align: center;
`;

export function errorCSS(isVisible: boolean): SerializedStyles {
  return css`
    height: 0;
    margin-top: 0;
    transition: height .3s ease-in-out, margin-top .3s ease-in-out;
    will-change: height, margin-top;

    ${
      isVisible
      && css`
        height: 20px;
        margin-top: 8px;
      `
    };
  `;
}
