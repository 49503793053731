import type { FC } from 'react';
import { visuallyHiddenCSS } from '../../shared.style.ts';
import { containerCSS } from './SVGButton.style.ts';
import type { SVGButtonProps } from './SVGButton.type.ts';

const SVGButton: FC<SVGButtonProps> = ({ customCSS, children, name, onClick }) => {
  return (
    <button type="button" css={[containerCSS, customCSS]} onClick={onClick}>
      {children}
      <span css={visuallyHiddenCSS}>{name}</span>
    </button>
  );
};

export default SVGButton;
