import { isArraysEqual } from '../shared/utils/array-helpers.util.ts';
import type { AnswerValue, Answers } from '../types/answer.type.ts';

export function isAnswersEqual(answers: Answers, oldAnswers: Answers): boolean {
  return Object.keys(answers).every((key) => {
    switch (true) {
      case typeof answers[key] !== typeof oldAnswers[key]:
        return false;
      case typeof answers[key] === 'string':
      case typeof answers[key] === 'number':
      case answers[key] === null:
      case typeof answers[key] === 'boolean':
        return answers[key] === oldAnswers[key];
      case Array.isArray(answers[key]):
        return isArraysEqual<string>(answers[key] as string[], oldAnswers[key] as string[]);
      default:
        return false;
    }
  });
}

export function questionHasAnswer(answerValue?: AnswerValue | Record<string, never>): boolean {
  switch (true) {
    case answerValue === null:
      return false;
    case typeof answerValue === 'string':
      return answerValue !== '';
    case Array.isArray(answerValue):
      return answerValue.length > 0;
    case typeof answerValue === 'object' && answerValue !== null:
      return Object.values(answerValue).some(value => !!value);
    case typeof answerValue === 'number':
      return true;
    case typeof answerValue === 'boolean':
      return true;
    default:
      return false;
  }
}

export function isValueSpecificType<Type extends AnswerValue>(
  value?: AnswerValue,
  types: ('string' | 'number' | 'null' | 'array' | 'address')[] = [],
): value is Type {
  return (
    (types.includes('string') && typeof value === 'string')
    || (types.includes('number') && typeof value === 'number')
    || (types.includes('array') && Array.isArray(value))
    || (types.includes('null') && value === null)
    || (types.includes('address')
      && typeof value === 'object'
      && value !== null
      && Object.hasOwn(value, 'zip')
      && Object.hasOwn(value, 'city'))
  );
}
