import { css } from '@emotion/react';

import { mq } from '../../../styles/media-queries.ts';

export const formInnerCSS = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-right: 0;
  ${mq[1]} {
    margin-right: -16px;
  }
`;

export const validationErrorCSS = css`
  margin-top: 12px;
`;

export const buttonCSS = css`
  margin-top: 4px;
`;
