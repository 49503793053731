import type { FC } from 'react';
import PageContainer from '../../components/PageContainer/PageContainer';
import CloseLook from '../../assets/svg/content-pages/close-look.svg?react';
import Stopwatch from '../../assets/svg/content-pages/stopwatch.svg?react';
import Card from '../../assets/svg/content-pages/card.svg?react';
import Doc from '../../assets/svg/content-pages/doc.svg?react';
import ChartDown from '../../assets/svg/content-pages/chart-goes-down.svg?react';
import SIRV_IMAGES from '../../constants/sirv-images';
import { contentPageContainerCSS } from '../../styles/content-pages.style';
import { pageHeadingCSS } from '../../styles/layout.style';
import { AnalyticPageKey } from '../../types/analytics.type.ts';
import useTrackPage from '../../hooks/use-track-page.ts';
import {
  creditImpactsCSS,
  heroDescriptionCSS,
  heroImgCSS,
  heroSubheadingCSS,
  improveSuggestionCSS,
  infoCSS,
} from './CreditScoreDetailsLayout.style';

const CreditScoreDetailsLayout: FC = () => {
  useTrackPage(AnalyticPageKey.CreditScoreDetails);

  return (
    <PageContainer
      pageKey={AnalyticPageKey.CreditScoreDetails}
      customCSS={{ innerContainer: contentPageContainerCSS }}
      showBackButton
    >
      <section>
        <h1 css={pageHeadingCSS}>More about Credit Score</h1>
        <div css={heroSubheadingCSS}>Insurance and Your Credit Score: What You Need to Know</div>

        <img alt="" src={SIRV_IMAGES.manOnSpeedometer} css={heroImgCSS} />

        <div css={heroDescriptionCSS}>Your credit score is an important part of your financial picture.
          It’s used during all kinds of big life moments — like getting approval to rent an apartment,
          taking out a loan (and determining your interest rate), applying for credit cards, and more.
          But did you also know that in most US states, your credit may impact your home insurance rates?
        </div>
      </section>

      <section>
        <h2>How your credit affects your insurance options</h2>

        <p>Your credit rating impacts what is known as your credit-based insurance score, which is a number used
          by insurance carriers to determine whether they offer insurance to you and what you pay for it. Your
          insurance score is different from your credit score, but is calculated using some credit-related factors —
          like your payment history, credit history length, and any debt you owe. It’s usually based on your
          credit report from a major credit bureau, like Experian, TransUnion, or Equifax.
        </p>
        <p>Another thing to note is that you won’t have one set insurance score, since each insurance carrier
          calculates this number in a slightly different way. Regardless of how your score is calculated, though,
          one theme carries across the board for most carriers — if you have a lower credit-based insurance score,
          you’ll likely pay more in premiums.
        </p>
        <p>Are you surprised by this information? If so, you’re not alone. In a Matic survey, almost half of all
          respondents — 45% — did not know that their credit impacts their insurance rates and their ability to
          get coverage. This finding was even more pronounced for respondents over the age of 60, where 54% said
          they didn’t know that their credit affected their premiums.*
        </p>

      </section>

      <section>
        <div css={creditImpactsCSS}>
          <img alt="" src={SIRV_IMAGES.radialChart} />
          <p>Did you know that your credit impacts your insurance rates?</p>
        </div>
      </section>

      <section>
        <h2>Why do insurance carriers use your credit information?</h2>

        <p>Insurance carriers can use your credit-based insurance score as one of many elements to come up with your
          rate and decide whether to offer you a policy. It’s used as a way to predict how likely you are to file a
          claim (which costs the company money).
        </p>
        <p>What does your credit score have to do with your chances of filing a claim? Some industry research from the
          Federal Trade Commission shows that people with lower credit scores file more claims than those with good or
          excellent credit scores — and therefore, your credit history helps insurers predict the level of risk to
          issue you a policy. You may be able to improve your credit rating as described below.
        </p>
      </section>

      <section>
        <h2>How to improve your credit score</h2>
        <p>Now that you know about the importance of your credit score in relation to insurance (for most US states),
          let’s take a look at a few ways to increase your credit score over time. Improving your credit score
          will help you secure a home or auto insurance policy and get access to the best rates.
        </p>

        <div>
          <div css={improveSuggestionCSS}>
            <CloseLook />
            <div>
              <h3>Understand your credit score.</h3>
              <p>Make sure you know your credit score and understand what factors are influencing that score, so you can
                take steps to improve. You can easily access this information for free from one of the
                three major credit bureaus.
              </p>
            </div>
          </div>

          <div css={improveSuggestionCSS}>
            <Stopwatch />
            <div>
              <h3>Pay bills on time.</h3>
              <p>Your payment history is the biggest factor that influences your credit score. One way to combat
                late payments (if you’ve forgotten to pay your bill on time) is to set up automatic bill payments
                from your bank account, so you don’t have to keep track of due dates on your own.
              </p>
            </div>
          </div>

          <div css={improveSuggestionCSS}>
            <ChartDown />
            <div>
              <h3>Keep your credit utilization low.</h3>
              <p>Credit utilization is the percentage of your available credit that you use each month. If you
                have access to $20,000 of credit on two credit cards, for example, and you’ve spent $10,000 between the
                two cards, your credit utilization rate is 50%.
              </p>
              <p>It’s usually recommended to keep your credit
                utilization rate under 30%. So, if you have access to $20,000 of credit, you shouldn’t use more than
                $6,000 of it. Why is this? A low credit utilization rate illustrates that you’re managing your finances
                responsibly and likely not spending above your means.
              </p>

              <h4>A few ways to decrease your credit utilization rate include:</h4>
              <ul>
                <li>
                  <span>Making fewer purchases with credit cards. </span>
                  If you can pay some of your bills directly through your bank account, consider making the switch.
                </li>
                <li>
                  <span>Pay your balances in full. </span>
                  If you can’t do this, pay off as much as you can to keep your balance as low as possible.
                </li>
                <li><span>Open a new credit card.</span> Getting approved for a new credit card means you’ll have a
                  new line of credit with its own credit limit. This adds to your available credit and therefore, can
                  help lower your credit utilization rate — if you’re careful. Opening a new account doesn’t mean you
                  have more spending power, and it’s easy to overspend with a shiny new card. Plus, opening a new
                  account adds an extra hard inquiry to your credit report (more on this later), which may lower
                  your credit score.
                </li>
                <li><span>Ask your credit card issuer for a limit increase.</span> Some credit card companies will bump
                  up your card limit if you simply ask for an increase. This is especially true if your financial
                  situation has changed — for instance, you got a new job that pays more, and are eligible for
                  a higher limit.
                </li>
              </ul>
            </div>
          </div>

          <div css={improveSuggestionCSS}>
            <Card />
            <div>
              <h3>Don’t close your old accounts.</h3>
              <p>You might be tempted to close an old credit card that you don’t use, but that could be a mistake.
                The length of your credit history contributes to your credit score — generally speaking, the
                longer you’ve had credit available, the better. Plus, if you close an account, you’ll lose access to the
                available credit on that card, and your credit utilization rate will go up.
              </p>
            </div>
          </div>

          <div css={improveSuggestionCSS}>
            <Doc />
            <div>
              <h3>Avoid hard inquiries, if possible.</h3>
              <p>Do your best to limit the number of hard inquiries on your credit report. A hard inquiry represents an
                instance where a new creditor — like a credit card issuer or a lender — has pulled your credit report
                when you consented for them to do so. If you have many hard inquiries within a short time period
                (maybe you applied for too many credit cards at once), your credit score may be negatively affected.
                The good news is that a few hard inquiries over a longer period of time likely won’t
                have a long-term effect on your score.
              </p>
              <p>When you get an insurance quote, you might be concerned that your credit score could plummet from the
                check on your credit report. This is a myth. While insurance carriers do check your credit report
                during the quoting process, it’s considered a soft inquiry when they do so. A soft inquiry is simply a
                check on your credit that doesn’t affect your credit score. Soft inquiries include things like checking
                your own credit report and getting pre-approved offers from lenders (that you didn’t apply for). Because
                of this, you can get as many insurance quotes as you want without worrying about your score.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <p css={infoCSS}>
          *Survey excluded people living in states where insurance rates are not impacted by credit score.
        </p>
      </section>

    </PageContainer>
  );
};

export default CreditScoreDetailsLayout;
