import type { AddressAnswer } from '../../../types/answer.type';
import type { AddressSuggestion } from '../../../types/smarty-streets.type';

export function getDefaultValueString(value: AddressAnswer): string {
  return value?.line1 ? `${value.line1} ${value.city}, ${value.state} ${value.zip}`.trim() : '';
}

export function suggestionToAnswer(suggestion: AddressSuggestion): AddressAnswer {
  return {
    line1: `${suggestion.street_line} ${suggestion.secondary}`.trim(),
    city: suggestion.city,
    state: suggestion.state,
    zip: suggestion.zipcode,
  };
}
