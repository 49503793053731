import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { ANALYTICS_SESSION_GID } from '../../../constants/browser-storage';
import useSegment from '../../../hooks/use-segment';
import useSendMagicLink from '../../../api/authentification/use-send-magic-link';
import { AnalyticPageKey } from '../../../types/analytics.type';
import ROUTES from '../../../constants/routes';
import Button from '../../../shared/components/Button/Button';

export const SignUpEmailReplacer: FC = () => {
  const { getValues } = useFormContext();
  const email = getValues('email');

  const { mutateAsync: sendMagicLink, isPending } = useSendMagicLink();

  const { track } = useSegment();
  const navigate = useNavigate();

  const onActionClicked = async (): Promise<void> => {
    const sessionGid = localStorage.getItem(ANALYTICS_SESSION_GID) || '';

    track('Authenticate Link Sent', {
      page: AnalyticPageKey.SignUp,
      session_gid: sessionGid,
    });

    const res = await sendMagicLink({
      email,
      session_gid: sessionGid,
    });
    navigate(`${ROUTES.checkEmail}?email=${res.account.email}`, { state: { email } });
  };

  return (
    <Button onClick={onActionClicked} variant="text" disabled={isPending}>
      Send an email sign in link instead?
    </Button>
  );
};
