import type { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import PageContainer from '../../components/PageContainer/PageContainer';
import { contentPageContainerCSS } from '../../styles/content-pages.style';
import { pageHeadingCSS } from '../../styles/layout.style';
import { AnalyticPageKey } from '../../types/analytics.type.ts';
import type { DefaultRouteParams } from '../../types/route-params.type.ts';
import ROUTES from '../../constants/routes.ts';
import useTrackPage from '../../hooks/use-track-page.ts';

const DisclaimersLayout: FC = () => {
  const { gid } = useParams() as DefaultRouteParams;
  useTrackPage(AnalyticPageKey.Disclaimers);

  return (
    <PageContainer
      pageKey={AnalyticPageKey.Disclaimers}
      customCSS={{ innerContainer: contentPageContainerCSS }}
      showBackButton
    >
      <section>
        <h1 css={pageHeadingCSS}>Disclaimers</h1>

      </section>

      <section>
        <h2>Protection Snapshot</h2>

        <p>Risk and Protection scores and factors are provided for illustrative purposes only and do not predict
          actual outcomes. Each consumer is responsible for evaluating their own coverage needs and obtaining
          adequate protection.
        </p>
        <p>Matic uses available information to estimate risk and protection scores and factors. Missing or incorrect
          information may reduce the accuracy of the illustrations. If and when additional information is provided
          your risk and protection scores may change.
        </p>
      </section>

      <section>
        <h2>Risk Score and Factors</h2>

        <p>The risk score is a representation of how carriers view the risk to insure a home. The risk score is
          intended to show how risk level may impact insurance premiums and carrier options. Risk scores may change.
        </p>
        <p>The factors used to calculate this risk score include location, claims, credit history, home age, roof
          condition, pets, security hazards and
          heating source. <Link to={ROUTES.snapshotDetails.replace(':gid', gid)}>View your factors.</Link>
        </p>
        <p>Matic’s rating of risk level for each factor is based on experience with its panel of insurance carriers
          and is provided for illustrative purposes only. The actual rating of risk by an insurance carrier to
          make underwriting decisions may differ.
        </p>
      </section>

      <section>
        <h2>Protection Score and Factors</h2>

        <p>The protection score is a representation of protection level based on coverage and actions taken to mitigate
          risk of potential damage. The protection score may indicate that additional coverage would be beneficial to
          mitigate risk in the event of a loss. Each consumer is responsible for evaluating their own coverage needs and
          obtaining adequate protection. Protection scores may vary.
        </p>
        <p>Matic uses available information to estimate protection scores and factors. The level of protection
          identified for each factor used to generate a protection score is based on Matic’s standards for home
          insurance policy coverages, as well as actions taken to mitigate risk of
          potential damage. <Link to={ROUTES.snapshotDetails.replace(':gid', gid)}>View your factors.</Link>
        </p>
      </section>

      <section>
        <h2>Matic Quotes and Coverages</h2>

        <p>Quotes are estimates only. Quotes are subject to change without notice. Your actual rate, payment and
          coverage may be different. Quotes do not constitute an offer of insurance, nor is any contract, agreement,
          or insurance coverage implied, formed or bound by the provision of quotes. Insurability, final insurance
          premium quotes and an offer of insurance, if any, will be determined by the insurance company providing
          your insurance policy.
        </p>
        <p>Any coverage descriptions provided are for informational purposes only. Matic’s lowest quote may be based
          on coverages, limits, exclusions, and deductibles that differ from those of an individual’s existing policy.
          Refer to the applicable policy for the details. Each consumer is responsible for evaluating their own
          coverage needs and obtaining a policy that meets those needs.
        </p>
      </section>

      <section>
        <h2>Estimated Premium</h2>

        <p>Estimated premium is based on current premium and the state-specific average percent increase of premiums
          renewing this year and next year. Rates and discounts vary and are subject to change. Matic’s prediction model
          has a mean absolute percentage error of +/- 9%, meaning that on average, predictions may be off by about 9% in
          either direction compared to actual renewal premium.
        </p>
      </section>

      <section>
        <h2>Replacement Cost</h2>

        <p>Estimated future replacement cost is based on a current policy Replacement Cost coverage
          (aka Dwelling coverage) and the average state-specific percent increase of Replacement Cost coverage.
          Actual replacement cost may vary and is subject to change.
        </p>
        <p>Suggested replacement cost range is estimated based on your quoted Replacement Cost coverage
          (aka Dwelling coverage) and the average percent increase of Replacement Cost coverage in your state.
        </p>
        <p>Your current replacement cost is based on the Replacement Cost coverage limit (aka Dwelling coverage limit)
          included in your current policy.
        </p>
      </section>

    </PageContainer>
  );
};

export default DisclaimersLayout;
