import { useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api.ts';
import useSessionTimeoutStore from '../../context/session-timeout-context.ts';
import type { HTTPResponse } from '../../types/api.type.ts';
import api from '../api.ts';
import type { QuotesOverTimeHistoryResponse } from '../../types/history.type.ts';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys.ts';
import { checkResponseType } from '../../utils/error.util.ts';
import { QuotesOverTimeHistoryResponseSchema } from './history.schema.ts';

export default function useQuotesOverTimeQuery(gid: string, enabled?: boolean) {
  const updateLastUserInteractionTime = useSessionTimeoutStore(s => s.updateLastUserInteractionTime);

  return useQuery({
    queryKey: [QUERY_CACHE_KEYS.quotesOverTimeHistory, gid],
    enabled,
    queryFn: async () =>
      await api
        .get<HTTPResponse<QuotesOverTimeHistoryResponse>>(
          API_PATH.quotesOverTimeHistory().replace(':gid', gid),
        )
        .then((res) => {
          if (res.session.refreshed_at) {
            updateLastUserInteractionTime(res.session.refreshed_at * 1000);
          }

          return checkResponseType<QuotesOverTimeHistoryResponse>(res.data, QuotesOverTimeHistoryResponseSchema);
        }),
  });
}
