import type { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAccountQuery from '../../api/account/use-account-query.ts';
import CalendarIcon from '../../assets/svg/calendar-icon.svg?react';
import PhoneIcon from '../../assets/svg/phone.svg?react';
import UserWithChat from '../../assets/svg/user-with-chat.svg?react';
import ROUTES from '../../constants/routes';
import useResponsive from '../../hooks/use-responsive';
import useSegment from '../../hooks/use-segment';
import Anchor from '../../shared/components/Anchor/Anchor';
import Button from '../../shared/components/Button/Button';
import { buttonCSS } from '../../shared/components/Button/Button.style';
import Toggletip from '../../shared/components/Toggletip/Toggletip';
import type { DefaultRouteParams } from '../../types/route-params.type.ts';
import Format from '../../utils/format.util';
import useSettingsQuery from '../../api/settings/use-settings-query.ts';
import { AnalyticLocation } from '../../types/analytics.type.ts';
import { FS_SENSITIVE_DATA_CLASS } from '../../constants/full-story.ts';
import {
  actionButtonCSS,
  buttonIconCSS,
  headerTextCSS,
  scheduleCallCSS,
  subHeaderTextCSS,
  textWrapperCSS,
  toggletipButtonCSS,
  toggletipContentCSS,
  userWithChatIconCSS,
} from './LiveHelpButton.style';
import type { LiveHelpButtonProps } from './LiveHelpButton.type.ts';

const LiveHelpButton: FC<LiveHelpButtonProps> = ({ pageKey }) => {
  const { gid } = useParams() as DefaultRouteParams;
  const { isMobile } = useResponsive();
  const { data: settingsData } = useSettingsQuery();
  const { data: accountData } = useAccountQuery(gid);
  const phoneNumber = settingsData?.phone_number || '';
  const personName = accountData?.account.first_name;
  const { track } = useSegment();
  const navigate = useNavigate();

  const onLiveHelpClick = (): void => {
    track('Live Help Clicked', { page: pageKey });
  };

  const onPhoneClicked = (): void => {
    track('Phone Conversation Initiated', {
      page: pageKey,
      location: AnalyticLocation.Header,
    });
  };

  const onScheduleCall = (): void => {
    track('Conversation Scheduling Initiated', {
      page: pageKey,
      location: AnalyticLocation.Header,
    });
    if (gid) {
      navigate(ROUTES.scheduleCall.replace(':gid', gid));
    }
  };

  return (
    <Toggletip
      content={(
        <div css={toggletipContentCSS}>
          <div css={textWrapperCSS}>
            <div css={headerTextCSS}>
              Hi <span className={FS_SENSITIVE_DATA_CLASS.mask}>{personName}</span>,
            </div>
            <div css={subHeaderTextCSS}>Here’s how we can help:</div>
          </div>
          <div>
            <Anchor css={[buttonCSS('middle'), actionButtonCSS]} href={`tel:${phoneNumber}`} onClick={onPhoneClicked}>
              <PhoneIcon css={buttonIconCSS} />
              Call: {Format.phone(phoneNumber)}
            </Anchor>
            <Button css={scheduleCallCSS} variant="secondary" onClick={onScheduleCall}>
              <CalendarIcon css={buttonIconCSS} />
              Schedule A Consultation
            </Button>
          </div>
        </div>
      )}
      customCSS={{ buttonCSS: toggletipButtonCSS }}
      onClick={onLiveHelpClick}
    >
      {!isMobile && <UserWithChat data-testid="chat-icon" css={userWithChatIconCSS} />}
      Live Help
    </Toggletip>
  );
};

export default LiveHelpButton;
