import * as currencyFormatter from 'currency-formatter';

const isNumber = (value?: number | string) => value && +value === +value; // eslint-disable-line no-self-compare

export function moneyFormatter(
  money?: number | string,
  shortFormat = false,
  precision = 0,
): string | number | undefined {
  if (!isNumber(money)) {
    return money;
  }

  const amount = Number(money);
  const thousands = amount / 1000;

  if (shortFormat && thousands >= 10) {
    return `$${currencyFormatter.format(thousands, { precision: thousands % 1 === 0 ? 0 : 1 })}k`;
  }

  return `$${currencyFormatter.format(precision ? amount : Math.round(amount), { precision })}`;
}

export function phoneFormatter(phone?: string | number): string | undefined {
  return (
    (phone
      && String(phone)
        .replace(/[^+0-9]/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'))
      || undefined
  );
}
