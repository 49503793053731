import { type FC, useContext, useEffect } from 'react';

import useTrackProposalOpened from '../../../api/proposal/use-track-proposal-opened';
import { PROPOSAL_SEGMENTS } from '../../../constants/analytics';
import type { Subscription } from '../../../services/ActionCable';
import noop from '../../../shared/utils/noop';
import { ActionCableContext } from '../../../context/action-cable-context.ts';
import type { ProposalsPresenceTrackerProps } from './ProposalPresenceTracker.type';
import useTrackProposalPage from './ProposalPresenceTracker.util';

const ProposalPresenceTracker: FC<ProposalsPresenceTrackerProps> = ({ proposal: { gid: proposalGid } }) => {
  const cable = useContext(ActionCableContext);
  const { mutate: trackProposalOpened } = useTrackProposalOpened();
  useTrackProposalPage(PROPOSAL_SEGMENTS.pages.proposal);

  useEffect(() => {
    if (proposalGid) {
      trackProposalOpened(proposalGid);
    }
  }, [trackProposalOpened, proposalGid]);

  useEffect(() => {
    let proposalPresenceSubscription: Subscription;
    let heartbeatInterval: NodeJS.Timeout;

    if (cable) {
      proposalPresenceSubscription = cable.subscriptions.create(
        { channel: 'ProposalPresenceChannel', proposal_gid: proposalGid },
        { received: noop },
      );

      heartbeatInterval = setInterval(() => {
        proposalPresenceSubscription.perform('heartbeat');
      }, 1000);
    }

    return () => {
      if (cable) {
        proposalPresenceSubscription.unsubscribe();
      }

      if (heartbeatInterval) {
        clearInterval(heartbeatInterval);
      }
    };
  }, [cable, proposalGid]);

  return null;
};

export default ProposalPresenceTracker;
