import type { z } from 'zod';
import type {
  AccountResponseSchema,
  AccountSchema,
  AccountUnauthorizedResponseSchema,
  SignUpPayloadSchema,
} from '../api/account/account-query.schema.ts';
import type { TargetPage } from './target-page.ts';

export type Account = z.infer<typeof AccountSchema>;
export type SignUpPayload = z.infer<typeof SignUpPayloadSchema>;

export type AccountResponse = z.infer<typeof AccountResponseSchema>;
export type AccountUnauthorizedResponse = z.infer<typeof AccountUnauthorizedResponseSchema>;

export interface SendMagicLinkPayload {
  gid?: string;
  person_gid?: string;
  email?: string;
  target_page?: TargetPage;
  session_gid?: string;
}

export enum AccountCreationMethod {
  RateReview = 'rate_review',
  SelfCreated = 'self_created',
}
