import { useMutation } from '@tanstack/react-query';
import API_PATH from '../../constants/api.ts';
import type { HTTPResponse } from '../../types/api.type.ts';
import type { AccountUnauthorizedResponse, SignUpPayload } from '../../types/account.type.ts';
import api from '../api.ts';
import { checkResponseType } from '../../utils/error.util.ts';
import { AccountUnauthorizedResponseSchema } from './account-query.schema.ts';

function useCreateAccount() {
  return useMutation({
    throwOnError: false,
    mutationFn: async (data: SignUpPayload) => {
      const { session_gid, ...account } = data;

      return await api
        .post<HTTPResponse<AccountUnauthorizedResponse>>(API_PATH.account.replace('/:gid', ''), {
          body: { data: { session_gid, account } },
        })
        .then(async (res) => {
          return checkResponseType<AccountUnauthorizedResponse>(res.data, AccountUnauthorizedResponseSchema);
        });
    },
  });
}

export default useCreateAccount;
