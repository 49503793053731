import { z } from 'zod';

export const DigitalProfileSchema = z.object({
  completed_at: z.date().nullable(),
  url: z.string().url(),
});

export const DigitalProfileResponseSchema = z.object({
  profile: DigitalProfileSchema,
});
