import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import { mq } from '../../../styles/media-queries';

export function containerCSS(isIconLeft: boolean): SerializedStyles {
  return css`
    display: flex;
    align-items: center;
    gap: 20px;
    flex-direction: ${isIconLeft ? 'column' : 'column-reverse'};
    text-align: center;

    ${mq[2]} {
      gap: 80px;
      flex-direction: row;
      text-align: start;

      svg {
        flex-shrink: 0;
      }
    }
  `;
}

export const titleCSS = css`
  margin: 0 0 12px;
  font-size: 20px;
  line-height: 28px;

  ${mq[2]} {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 20px;
  }
`;

export const descriptionCSS = css`
  font-size: 16px;
  line-height: 20px;

  ${mq[2]} {
    font-size: 20px;
    line-height: 28px;
  }
`;
